.ui.four.cards > .card {
  width: auto;
  margin-left: 0;
  margin-right: 0;
}

.bulk-card-heading {
  font-size: 16px;
  font-weight: 500;
  /* font-family: 'Outfit'; */
  color: #646565;
  margin-bottom: 5px;
}

.business-card-review {
  font-weight: 500;
  color: rgb(139, 139, 141);
}

.custom-progress {
  width: 103px; /* Set desired width here */
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 255px;
}

.chart-container .recharts-wrapper {
  width: 100%;
  height: 100%;
}

/* .chart-container canvas {
  height: 346px !important;
  width: 346px !important;
}

.doughnut-chart {
  width: 100%;
  height: 100%;
} */

.custom-button {
  border-radius: 80px; /* Set the desired border-radius */
}

.card-container {
  padding: 25px;
}
.very-high-color {
  background-color: #57e32c !important;
  height: 8px;
}
.low-color {
  background-color: #ffa534 !important;
  height: 8px;
}
.moderate-color {
  background-color: #ffe234 !important;
  height: 8px;
}
.high-color {
  background-color: #b7dd29 !important;
  height: 8px;
}
.very-low-color {
  background-color: #ff4545 !important;
  height: 8px;
}
.business-summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
  padding: 10px 0;
}

.card-item.special-card {
  background-color: white;
  border-radius: 8px;
  padding: 5px 24px;
  border-bottom: 2px solid #f13a88;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.price-special-card{
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  border-bottom: 2px solid #f13a88;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.price-card {
  background-color:#FAFAFB;
  border-color:#F0F1F3;
  border-radius: 8px;
  padding:8px;
}

.card-content {
  display: grid;
  gap: 4px;
}

.card-heading {
  font-weight: 600;
  font-size: 18px;
  color: #1A2544;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badge {
  background-color: #f13a88;
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
}

@media screen and (max-width: 1745px) and (max-height: 100vh){
  .business-summary-grid {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 10px;
    padding: 10px 0;
  }

  .card-item.special-card {
    flex: 0 0 224px;
  }

  .business-summary-grid {
    -ms-overflow-style: none;
  }

  .business-summary-grid::-webkit-scrollbar {
    display: none;
  }
}

.business-card-number{
  margin-bottom: 0px !important;
  font-size: 1.5rem !important;
}

.bulk-card-content {
  border-bottom: 2px solid;
  border-color: #f13a88;
  padding: 15px 15px 11px 15px;
  display: grid;
  /* gap: 8px; */
  /* border-radius: 8px; */
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
}
.bulk-card-content h2 {
  font-size: 21px;
  margin-bottom: 2px;
}

.bulk-card-content .card-review {
  font-size: 14px !important;
  color: #ec554e !important;
  font-weight: 400 !important;
}

.flex-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex-container .badge {
  border: 1px solid;
  padding: 10px;
  color: white;
  border-radius: 20px;
  height: 25px;
  display: flex;
  align-items: center;
}

.flex-container .badge-added {
  background-color: #81e050;
  border-color: #81e050;
}

.flex-container .badge-new {
  background-color: #989fad;
  border-color: #989fad;
}

.badgelowcompletionstatus {
  border: 1px solid;
  padding: 10px;
  color: white;
  border-radius: 12px;
  height: 25px;
  display: flex;
  align-items: center;
  background-color: #ec554e;
  border-color: #ec554e;
}

.OverviewHealthStatusContainer {
  display: flex;
  gap: 24px;
}
.OverviewOverallHealth {
  width: 40%;
  padding: 10px 15px;
  border-radius: 16px;
  background-color: white;
}
.OverviewCompletionStatus {
  width: 60%;
  padding: 10px 15px;
  border-radius: 16px;
  background-color: white;
}
@media screen and (max-width: 1280px) {
  .OverviewHealthStatusContainer {
    display: block;
  }
  .OverviewOverallHealth {
    width: 100%;
  }
  .OverviewCompletionStatus {
    width: 100%;
    margin-top: 24px;
  }
}

.list-search .fp-input{
  height: 32px !important;
}

.list-search .fp-input input{
  padding: 7px 7px 7px 40px;
}
