.page-navigator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0px 20px;
  background-color: var(--background);
}

.page-card {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  width: 380px;
  padding: 22px 0px;
  transition: var(--transition);
  position: relative;
  overflow: hidden;
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page-content{
  max-height: 80%;
    overflow-y: auto;
    padding: 0% 5%;
}
@media (max-width: 768px) {
  .page-card {
    height: 95vh;
    width: 90vw;
  }
}

.logo-container {
  text-align: center;
  margin-bottom: 24px;
}

.logo {
  width: 120px;
  height: auto;
}

.page-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  text-align: center;
}

.page-description {
  font-size: 18px;
  color: var(--text-secondary);
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.5;
  padding: 0px 22px
}

.button-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 14px 20px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
  border: none;
  outline: none;
  text-align: center;
}

.btn-primary {
  background-color: var(--pink);
  color: var(--white);
}

.btn-primary:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.btn-icon {
  margin-left: 8px;
}


.form-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.form-control {
  display: block;
  width: 100%;
  padding: 6px 16px;
  font-size: 16px;
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  background-color: var(--white);
  transition: var(--transition);
}

.form-control:focus {
  outline: none;
  border-color: var(--pink);
  box-shadow: 0 0 0 2px rgba(255, 61, 127, 0.2);
}

.form-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;
  padding-right: 40px;
}

.location-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: var(--transition);
  border: 1px solid #F0F1F3;
  background-color: #FAFAFB;
}

.location-item:hover {
  box-shadow: 0 0 0 2px #e2e2ea;
}

.location-title {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 6px;
}

.location-title svg {
  margin-right: 8px;
  color: var(--pink);
}

.location-address {
  font-size: 14px;
  color: var(--text-secondary);
  display: flex;
  gap: 10px;
  margin-bottom: 6px;
}

.location-distance {
  font-size: 14px;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
}

.location-distance svg {
  margin-right: 6px;
  font-size: 12px;
}

.feed-search-container {
  position: relative;
  margin-bottom: 20px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: var(--gray);
}

.page-indicator {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: var(--text-secondary);
  width: 30%;
}

.welcome-image-container {
  display: flex;
  justify-content: center;
  margin: 16px 0 5px;
}

.welcome-image {
  max-width: 240px;
  height: auto;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.6s ease forwards;
}

.page-transition-enter {
  opacity: 0;
  transform: translateX(20px);
}

.page-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.page-transition-exit {
  opacity: 1;
  transform: translateX(0);
}

.page-transition-exit-active {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 300ms, transform 300ms;
}

.feed-date-marker {
  border-bottom: 1px dashed #D0D3D9;
  width: 35%;
  text-align: center;
  line-height: 0.1em;
  margin: 10px 0 10px;
}

.survey-div {
    border: 1px solid #FCA5CE;
    padding: 10px;
    border-radius: 10px;
    background: #FAFAFB;
} 


.feed-back-urls .MuiAvatar-img {
  background:#fff !important;
  padding: 5px;
}
.feed-back-urls .MuiChip-label {
  color: #fff !important;
  font-size: 11px;
}