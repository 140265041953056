.fp-chip.MuiChip-root{
    padding: 5px 11px;
    height: auto;
}

.fp-chip.MuiChip-root.MuiChip-deletable{
    padding-right: 3px;
}

.fp-chip.MuiChip-outlined{
    border-color: var(--fp-secondary-outline);
}

.fp-chip.MuiChip-root.MuiChip-sizeSmall{
    padding: 5px 8px;
    padding-top: 6px;
    padding: 25px;
    padding: 1px 6px;
    font-size: 10.5px;
}

.fp-chip .MuiChip-label {
    color: var(--fp-secondary-font-dark, #1B1C1D);
    font-size: 10.5px;
    line-height: 13px;
    font-weight: 400;
    padding: 0;
    white-space: break-spaces;
}

.fp-chip.MuiChip-deletable .MuiChip-label {
    padding-right: 8px;
}