.Select--multi .Select-value {
	border-radius: 20px !important;
    border-color: #00B5AD !important;
    display: inline-flex !important;
    flex-direction: row-reverse !important;
    background-color: white !important;
    color: #1B1C1D !important;
}
span.Select-value-icon {
    margin: -3px 5px 0px 0px;
    font-size: 18px;
}
.Select--multi .Select-value-icon {
    padding: 0px 5px 0px !important;
    margin-top: -2px !important;
    border-right: none !important;
}