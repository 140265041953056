.mobileTemp{
    position: relative;
}
.mobileTemp .mobileTextContainer{
    font-size: 14px;
    padding: 10px;
    border-radius: 0px 0px 15px 15px;
    position: absolute;
    width: calc(100% - 80px);
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    background-color: #eceaef;
}

.mobileTemp .mobileImg{
	position: relative;
    max-width: 360px;
    max-height: 695px;
}

/* Email-Temp */
.emailTemp .left .header {
  background-color: #f2f2f2;
  padding: 1rem;
  max-width: 50rem;
  margin: auto;
}
.emailTemp .left .header .previewBtn {
  margin-left: 20px;
  padding: 2px 10px;
  border-radius: 13px;
  background-color: #d2d2d2;
}
.emailTemp .left .main {
  text-align: center;
  margin: auto;
  padding: 15px 2.5rem;
  background-color: #fafafa;
  border-top: 2px solid #eaeced;
  border-bottom: 2px solid #eaeced;
  max-width: 50rem;
}
.emailTemp .ratingIcon {
  flex: auto;
}
.emailTemp .card-Box .ratingIcon span img {
  width: 50px;
}
.emailTemp .main .FPChip-logo{
  margin-right: 4px;
  width: 15px;
  height: 15px
}
.emailTemp .main .Fp-Chip{
  padding: 0px 3px;
}
.emailTemp .left .main .spanItalic {
  font-style: italic;
}
.emailTemp .spanBold {
  font-weight: bold;
}
.emailTemp .left .footer {
  background-color: #eaebed;
  margin: auto;
  padding: 1rem;
  max-width: 50rem;
  font-size: 14px;
  color: #848181;
  text-align: center;
}
/* Mobile */
.emailTemp .right {
position: relative;
}
.emailTemp .right .mobileImg{
   max-width: 360px;
    max-height: 695px;
}
.emailTemp .right .mobileContainer {
  width: 100%;
 position: absolute;
    top: 11%;
}
.right .mobileContainer .mobileContent .ratingIcon span img {
  width: 40px;
}
.right .mobileContainer .mobileContent .FpChip-Box .fp-chip.MuiChip-root {
    padding: 5px 6px;
}
.right .mobileContainer .mobileContent .FpChip-Box .fp-chip .MuiChip-label{
  font-size: 10px;
}
.emailTemp .right .FPChip-logo{
      margin-right: 6px;
       width: 15px;
    height: 15px;
}
.right .mobileContainer .socialMediaIcon{
    display: flex;
    justify-content: center;
    gap: 10px ;
    background-color: black; 
    padding: 15px 0px;
}
.right .mobileContainer .socialMediaIcon span{
    border-radius: 50% !important;
    padding: 2px 2px !important;
}
.emailTemp .right .footer {
    padding: 10px 5px;
    font-size: 12px; 
    background-color: #fff;
}