.user-details-text {
    color: #7A8193;
    font-weight: 300;
}


.review-text-wrapper {
    border: 0.5px solid #BFC2CB;
    padding: 16px;
    border-radius: 12px;
}

.ticket-badge .MuiBadge-badge{
    background-color: black;
    right: 10px;
    padding:0;
    font-size: 10px;
    min-width: 22px;
    height:22px;
    border-radius: 33px;
}