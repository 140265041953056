.generic-template-form .form-group {
    min-height: unset !important;
    position: static !important;
    line-height: 1 !important;
    display: flex;
    align-items: center;
}

.generic-template-form .form-group .form-check-input {
    position: static !important;
    margin: 0 !important;
}

.generic-template-form .form-group label {
    margin-left: 10px !important;
    margin-bottom: 0 !important;
}

.question-title input {
    all: unset;
    width: 100%;
    border-bottom: 1px solid var(--fp-secondary-outline);
    height: auto !important;
    padding-bottom: 5px;
}

.question-title input:focus {
    box-shadow: none !important;
    background-color: transparent !important;
}

.flex-between-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.generic-templates-branch .Select-value{
    all: unset;
    line-height: 1;
    height: 100%;
    display: inline-flex !important;
    text-align: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 13px;
}

.generic-templates-branch .is-disabled{
 opacity: 0.5;
}

.generic-templates-branch .is-disabled .Select-control{
    cursor: not-allowed
}

.Select.is-disabled .Select-arrow-zone {
    opacity: 1 !important;
    background: #F9F9F9;
}

