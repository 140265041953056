.template-showcase {
    /* max-width: 1200px; */
    margin: 10px 0px;
}

.tabs-container {
    display: flex;
    margin-bottom: 30px;
    position: relative;
}

.tab {
    padding: 15px 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    position: relative;
    transition: color 0.3s ease;
}

.tab:hover {
    color: #333;
}

.tab.active {
    color: var(--highlight-pink);
}

.tab.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--highlight-pink);
}

.action-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.create-bot-button {
    background-color: #1a2c4e;
    color: white;
    border: none;
    border-radius: 49px;
    padding: 3px 20px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}

.create-bot-button:hover {
    background-color: #2a3c5e;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.plus-icon {
    margin-right: 8px;
    font-size: 18px;
    font-weight: bold;
}

.template-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 20px;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .template-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (max-width: 480px) {
    .template-grid {
        grid-template-columns: 1fr;
    }

    .tab {
        padding: 12px 20px;
        font-size: 14px;
    }
}

.template-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    animation: cardAppear 0.5s ease forwards;
    opacity: 0;
    transform: translateY(20px);
    padding: 20px;
}

.template-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}

@keyframes cardAppear {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.template-card:nth-child(1) {
    animation-delay: 0.1s;
}

.template-card:nth-child(2) {
    animation-delay: 0.2s;
}

.template-card:nth-child(3) {
    animation-delay: 0.3s;
}

.template-card:nth-child(4) {
    animation-delay: 0.4s;
}

.template-card:nth-child(5) {
    animation-delay: 0.5s;
}

.template-card:nth-child(6) {
    animation-delay: 0.6s;
}

.template-header {
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.template-title h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    color: #00B5AD;
}

.template-date {
    display: block;
    font-size: 14px;
    color: #999;
    margin-top: 4px;
}

.whatsapp-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-content {
    background-color:#D9FDD3;
    padding: 15px;
    flex-grow: 1;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 8px;
}

.greeting,
.message,
.context,
.ticket-no,
.team-info,
.thanks,
.signature {
    margin-bottom: 8px;
}

.greeting {
    font-weight: 600;
}

.message {
    color: #333;
}

.context {
    font-weight: 500;
}

.ticket-no {
    margin-top: 10px;
}

.signature {
    font-weight: 500;
    margin-top: 10px;
}

.template-footer {
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.flow-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.flow-label {
    font-size: 18px;
    color: #666;
    margin-right: 5px;
}

.flow-value {
    font-size: 18px;
    font-weight: 400;
}

.flow-value.ticket {
    color: #0e0d0d;
}

.flow-value.welcome {
    color: #4285f4;
}

.flow-value.not-mapped {
    color: #ff8a00;
}

.phone-number {
    font-size: 16px;
    margin-right: 10px;
}

.view-button {
    color: #ff3b77;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: underline;
}

.view-button:hover {
    text-decoration: underline;
}