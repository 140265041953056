.agency-card-wrapper .fp-header {
    background-color: #FBFBFB;
    border-radius: 12px 12px 0 0;
    /* padding: 12px 20px; */
    padding: 8px 16px;
    border-top: 1px solid var(--fp-secondary-outline);
    border-right: 1px solid var(--fp-secondary-outline);
    border-bottom: 1px solid var(--fp-secondary-outline);
    border-left: 1px solid var(--fp-secondary-outline);
}

.ui.card.agency-card-wrapper {
    border: 1px solid var(--fp-secondary-outline) !important;
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.08) !important;
}

.agency-card-wrapper.ui.card .content {
    padding: 0 !important;
}

.agency-details-wrapper{
    padding: 10px;
    border-right: 1px solid var(--fp-secondary-outline);
    border-bottom: 1px solid var(--fp-secondary-outline);
    border-left: 1px solid var(--fp-secondary-outline);
    border-radius: 0px 0px 10px 10px;
}

.agency-icon-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
}

.agency-icon-container img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: fill;
    border: 2px solid var(--fp-School-bus-Yellow-500);
    padding: 3px;
}

.MuiChip-root.fp-chip.agency-category {
    background-color: var(--fp-Blue-Chill-50);
    padding: 8px 11px;
    padding-top: 9px;
}

.MuiChip-root.fp-chip.agency-category .MuiChip-label {
    color: var(--fp-Primary-TILLA-KARI, #00CBCB);
    font-size: 14px;
    font-weight: 400;
}

.avg-rating {
    font-size: 16px;
    font-weight: 500;
    color: #1A2544;
    margin-top: 3px;
}

.total-rating {
    margin-bottom: 0;
    font-size: 16px;
    color: #7A8193;
}

.total-rating span {
    color: #1B1C1D;
    font-weight: 500;
}

.joining-date,
.agency-name,
.agency-location {
    margin-bottom: 7px !important;
    line-height: 1 !important;
}

.agency-name {
    font-weight: 500;
    font-size: 16px;
}

.joining-date, .agency-location {
    color: #7A8193;
    font-size: 15px;
}

.agency-location {
    margin-left: -2px;
}
