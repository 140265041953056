/* Material Table ui */
.fp-table .MuiTableHead-root {
  background-color: var(--fp-surface-color, #f6f6f8) !important;
}

.fp-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
  color: var(--secondary-font-blue, #1a2544) !important;
  border-color: #e8e8e8 !important;
  font-size: 14px;
}

.fp-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  color: var(--fp-secondary-font-dark, #1b1c1d) !important;
  border: none !important;
  font-size: 13px;
  padding: 0.5%;
}

.fp-table.dashbord-table .MuiTableBody-root .MuiTableRow-root:last-child {
  border-bottom: none !important;
}

.fp-table.fp-ai-table-center
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  text-align: center;
}
.fp-table.fp-table-center
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  text-align: center;
  padding: 1%;
}

.fp-table-caption {
  margin-left: auto !important;
  color: var(--violet-red-600, #e12169);
  text-align: end;
  float: right;
}

/* Material Table ui end */

/* semantic-ui-react Table ui  */
/* .fp-table.ui {
	margin: 0 !important;
	border: 0 !important;
}

.fp-table.ui thead tr th {
	margin: 0 !important;
	padding: 16px !important;
	color: var(--secondary-font-blue, #1a2544) !important;
	background-color: var(--fp-surface-color, #f6f6f8) !important;
	border-color: #E8E8E8 !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	color: var(--secondary-font-blue, #1a2544) !important;
	border-color: #E8E8E8 !important;
	font-size: 14px;

}

.fp-table.ui tbody tr td {
	margin: 0 !important;
	padding: 16px !important;
	color: var(--fp-secondary-font-dark, #1B1C1D) !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
	font-size: 14px !important;
	color: var(--fp-secondary-font-dark, #1B1C1D) !important;
	border: none !important;
	font-size: 13px;
}

.ui.table thead tr:first-child > th:last-child,
.ui.table thead tr:first-child > th:first-child {
    border-radius: 0 !important;
} */
/* end semantic-ui-react Table ui  */

.text-wrap-table {
  width: "100px" !important;
  text-overflow: "ellipsis" !important;
  overflow: "hidden" !important;
}

.tableHeaderHover:hover {
  background-color: #ddeff0;
}

.fp-table .MuiTableHead-root .MuiTableRow-root .sortCellStyleHeader {
  color: #438e96 !important;
  background-color: #ddeff0 !important;
}

.fp-table .MuiTableBody-root .MuiTableRow-root .sortCellStyle {
  color: #438e96 !important;
}

.templateName {
  color: rgb(78, 78, 223);
  text-decoration: underline;
  cursor: pointer;
  text-decoration-color: blue;
}

.reportRating-tag {
  padding: 5px 12px;
  border-radius: 50px;
  background-color: #f6f6f8;
  font-size: 14px;
  color: #7a8193;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  gap: 3px;
  margin: auto;
}



.nps-rating-colour {
	border: 2px solid green;
	border-radius: 50%;
	padding: 10px;
  }
  
  .nps-rating-colour-red {
    border: 2px solid #EC554E;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    padding-inline:16px;
    justify-content: center;
    align-items: center;
  }
  
  .nps-rating-colour-green {
    border: 2px solid #81E050;
    border-radius: 50%;
    padding-inline: 16px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nps-rating-colour-yellow {
    border: 2px solid #FAE25A;
    border-radius: 50%;
    padding: 10px;
    padding-inline:16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Add the filled background classes */
  .nps-rating-colour-red.filled {
    background-color: #EC554E;
    color: white; /* Improve text readability */
    padding-inline:16px;

  }
  
  .nps-rating-colour-yellow.filled {
    background-color: #FAE25A;
    color: black; /* Yellow background needs dark text */
    padding-inline:16px;

  }
  
  .nps-rating-colour-green.filled {
    background-color: #81E050;
    color: white; /* Improve text readability */
    padding-inline:16px;
  }
  
  .nps-meter {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Changed to 10 columns */
    gap: 20px;
    justify-content: center;
  }

  .newDesignNps{
    padding: 18px !important;
    width: 46px;
    height:46px;
  }
  .newDesignNpsExp{
    width: 46px;
    height:46px;
  }

  .nps-score-center{
    display: grid;
    justify-content: center;
  }