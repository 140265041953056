/* Example theme */
.msts_theme_example {
	border: 1px solid silver;
  margin-top: 2px;
}
.msts_theme_example .msts__heading .msts__side {
			padding: 5px;
			text-align: center;
			color: #fff;
			font-weight: bold;
		}
.msts_theme_example .msts__heading .msts__side_available {
			background-color: #00caca;
		}
.msts_theme_example .msts__heading .msts__side_selected {
			background-color: #ec588d;
		}
.msts_theme_example .msts__subheading .msts__side_filter {
			padding: 5px;
		}
.msts_theme_example .msts__footer .msts__side {
			padding: 5px 15px;
			background-color: #ecf0f1;
			font-size: 0.75em;
			color: #7f8c8d;
			text-align: right;
		}
.msts_theme_example .msts__list {
		height: 140px;
		overflow-y: auto;
		overflow-x: hidden;
	}
.msts_theme_example .msts__list-item {
		padding: 5px 10px;
		transition: background-color ease-in 0.1s, color ease-in 0.1s
	}
.msts_theme_example .msts__list-item:hover {
	background-color: #ececef;
	/*color: #fff;*/
}
.msts_theme_example .msts__list-item_disabled {
			background-color: #ecf0f1;
			color: #7f8c8d;
}
.msts_theme_example .msts__list-item_disabled:hover {
	background-color: #ecf0f1;
	color: #7f8c8d;
}
.msts_theme_example .msts__list-item_highlighted {
			background-color: rgba(41, 128, 185, 0.25);
}
.msts_theme_example .msts__control {
		border: none;
		background: none;
		cursor: pointer;
		padding: 10px 3px;
		color: #bdc3c7;
		transition: color ease 0.15s
	}
.msts_theme_example .msts__control:hover {
	color: #95a5a6;
}
.msts_theme_example .msts__control[disabled] {
	color: #ecf0f1;
}
.msts_theme_example .msts__control_select-all:after {
	content: '\276F';
}
.msts_theme_example .msts__control_deselect-all:after {
	content: '\276E';
}
.msts_theme_example .msts__filter {
		position: relative;
	}
.msts_theme_example .msts__filter-input {
		width: 100%;
		box-sizing: border-box;
		padding: 5px;
		border: 1px solid silver;
	}
.msts_theme_example .msts__filter-clear {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 0 10px;
		font-size: 1.25em;
		color: silver;
		transition: color ease 0.15s
	}
.msts_theme_example .msts__filter-clear:after {
	content: '\D7';
	vertical-align: -webkit-baseline-middle;
}
.msts_theme_example .msts__filter-clear:hover {
	color: #c0392b;
}

.msts_theme_example.msts_disabled {
	background-color: #ecf0f1;
}
