.ReportsTop .content {
    margin-top: 8px !important;
    padding: 1px 8px !important;
}

.ReportsTop .content .header {
    font-size: 16px !important;
}

.report-Filter {
    height: 33px !important;
    min-width: 88px !important;
}

.report-Filter:hover {
    background-color: #28314F;
}

.report-FirstRow .filter-button {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
}

.report-FirstRow .nav-tabs .nav-link {
    color: #1B1C1D;
}

.report-FirstRow .nav-tabs .nav-link.active {
    color: #ffff !important;
    background-color: #5FAAB1;
    border-color: transparent;
    border-radius: 10px;
    border-bottom: 0px solid !important;
}

.report-FirstRow .nav-tabs .nav-link:hover,
.report-SecondRow .nav-tabs .nav-link:hover,
.report-ThirdRow .nav-tabs .nav-link:hover,
.report-FourthRow .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.nav.nav-tabs {
    border-bottom: 0.5px solid #BFC2CB;
}

.report-SecondRow .nav-tabs .nav-link {
    color: #667085;
}

.report-SecondRow .nav-tabs .nav-link.active {
    color: #F13A88 !important;
    border-bottom: 0px solid !important;
    background-color: transparent;
    border-color: transparent;
}

.report-ThirdRow .nav-tabs .nav-link {
    color: #F13A88 !important;
}

.report-ThirdRow .nav-tabs .nav-link.active {
    background-color: #FDF2F7;
    border-color: transparent;
    border-bottom: 2px solid #F13A88;
}

.report-FourthRow .nav-tabs .nav-link.active {
    background-color: transparent;
    border-color: transparent;
}

.report-FourthRow .nav-tabs .nav-link {
    color: #7A8193 !important;
}

.report-FourthRow .graph-Table-RightButton {
    margin: 21px 10px;
}

.report-FourthRow .nav.nav-tabs {
    border-bottom: transparent;
}

/* Report-Filter */
.reportFilter .ui.dropdown:not(.button)>.default.text,
.ui.default.dropdown:not(.button)>.text,
.ui.dropdown.select-range,
.Select-placeholder,
.ui.search.dropdown>.text {
    color: #646565;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
}

.reportFilter .ui.dropdown.select-range {
    width: 100% !important;
    padding: 8px 12px !important;
}

.Report-Filter-Popup .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    width: 360px;
}

.reportFilter span.Select-arrow {
    border: solid #7A8193 !important;
    border-width: 0px 1px 1px 0px !important;
    transform: rotate(45deg) !important;
    padding: 3px;
}

.reportFilter .ui.dropdown>.dropdown.icon:before {
    border: solid #7A8193 !important;
    border-width: 0px 1px 1px 0px !important;
    transform: rotate(45deg) !important;
    padding: 3px;
}