:root {
  --small-screen: 599px;
}

body {
  font-size: 13px !important;
  color: #1b1c1d;
  background-color: #f6f6f8 !important;
  font-variant: normal !important;
  font-feature-settings: normal !important;
}

/* 
background: white;
min-width: 200px;
padding: 10px 12px;
border: 1px solid var(--fp-secondary-outline); */

.login-bg {
  background: #f6f6f8;
}

.img-background {
  background-image: url("../images/Rectangle.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
}

.heading-text {
  font-size: 38px;
  font-weight: 300;
}

.heading-text-sm {
  font-size: 30px;
  font-weight: 300;
}

.middle-card {
  /* position : 'absolute';
	top: 50%;
	left: 50%; */
  transform: translate(-50%, -50%);
}

@font-face {
  font-family: "Outfit";
  src: url("../fonts/Outfit-VariableFont_wght.ttf") format("truetype");
}

body {
  font-family: "Outfit", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3rem;
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
  text-align: center;
  opacity: 0.5;
}

.app-outer {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 20px 0;
  position: absolute;
  right: 0;
  top: 0;
}

.app-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1000px;
  width: 100%;
}

.tag-cloud {
  flex: 1;
}

.tag-cloud > div {
  transition: 1.4s;
}

.tag-item-wrapper {
  cursor: pointer;
}

.tag-item-wrapper:hover {
  z-index: 100;
}

.tag-item-wrapper:hover .tag-item-tooltip {
  display: block;
}

.btn-upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-upload input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: 0;
  max-width: 100%;
  height: 100%;
  display: block;
}

.btn-upload .btn {
  padding: 8px 20px;
  background: #337ab7;
  border: 1px solid #2e6da4;
  color: #fff;
  border: 0;
}

.btn-upload:hover .btn {
  padding: 8px 20px;
  background: #2e6da4;
  color: #fff;
}

.shadow {
  box-shadow: 20px 20px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.tag-item-tooltip {
  background: #c01916;
  color: white;
  margin-top: 5px;
  z-index: 100;
  display: none;
  padding: 15px;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  min-width: 60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.text-blue {
  color: #0000ff;
}

.card-gradient {
  background-image: linear-gradient(to bottom right, aqua, white);
  /* background: aqua; */
}

/*generic css*/

.full-height {
  min-height: 100vh;
}

.full-width {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.fw3 {
  font-weight: 300;
}

.fw5 {
  font-weight: 500;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-red {
  color: #d80027;
}

.text-green {
  color: #00c147;
}

.text-gray {
  color: #666;
}

.lh-30 {
  line-height: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

/* .link-text {
	color: #007bff;
} */

.link-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.custom-badge {
  padding: 1px 4px;
  border-radius: 2px;
  margin-bottom: 3px;
  background: #dadada;
}

.custom-badge i {
  /* float: right; */
  padding-top: 5px;
  margin-left: 5px;
  font-size: 10px;
  padding-right: 2px;
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.fa-1x {
  font-size: 1.7em;
}

.bg-White {
  background-color: #fff;
}

.bg-Gray {
  background-color: #dbdbd7 !important;
}

.bg-Green {
  background-color: #17b26a !important;
  /* box-shadow: #2c8a00 1px 1px; */
}

.bg-Orange {
  background-color: #fdb022 !important;
  /* box-shadow: #a75e00 1px 1px; */
}

.bg-Red {
  background-color: #f04438 !important;
  /* box-shadow: #af0000 1px 1px; */
}

.bb-gray {
  border-bottom: 1px solid #ebebeb;
  z-index: 1;
}

.fg-height {
  min-height: initial !important;
}

.grid-form .fg-height {
  height: 50px;
}

.grid-form .hgt .fg-height {
  height: 40px !important;
}

.feedback-form .fg-height {
  height: 100% !important;
}

.grid-form.textarea .fg-height {
  height: 85px;
}

.grid-form label {
  margin-top: 6px;
}

.grid-form .form-group {
  margin-bottom: 0;
}

.no-label label {
  display: none;
}

.form-group-sm .form-group {
  margin-bottom: 5px !important;
}

.invalid-feedback {
  margin-top: 0 !important;
}

.form-group {
  min-height: 61px;
}

.invalid-feedback {
  display: block !important;
}

.text-lg {
  font-size: 70px;
}

.field-error {
  margin-top: -26px !important;
  margin-bottom: 7px;
}

.inline {
  display: inline-block;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.max-height {
  height: 100% !important;
}

.img-preview-block img {
  width: 150px !important;
  height: 150px !important;
}

.scroll50Y {
  max-height: 50vh !important;
  overflow-y: auto !important;
}

.bg-menu {
  background-color: #1a2544 !important;
  z-index: 2;
}

/*semantic class overight*/

.form-group .ui.input {
  width: 100%;
}

.ui.form .Select.is-disabled {
  opacity: 0.45;
}

.ui.form .Select input {
  border: 0px;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-1em {
  margin-top: 1em !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mlr-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mlr-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mr-4 {
  margin-left: 2px !important;
}

.heading {
  font-family: "Outfit", sans-serif !important;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 1.28571429em;
  margin-top: -0.21425em;
  line-height: 1.28571429em;
}

.ui.card .agency-card-name {
  /* height: 50px !important; */
  height: max-content;
  overflow: hidden;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* line-height: 50px; */
  font-weight: bold;
  padding: 10px;
  width: 100%;
}

.ui.card .agency-hotel-name {
  background: rgb(199, 252, 212);
}

.ui.card .agency-retail-name {
  background: rgb(251, 199, 255) !important;
}

.ui.card .agency-restaurant-name {
  background: rgb(243, 190, 171) !important;
}

.ui.card .agency-health-name {
  background: rgb(158, 236, 255) !important;
}

.ui.card .agency-ecomm-name {
  background: rgba(205, 97, 255, 0.514);
}

.ui.card .agency-description-items,
.ui.popup .agency-description-items {
  height: 20px;
  display: flex;
  line-height: 20px;
  justify-content: center;
  /* flex-direction: row;
	align-items: center; */
}

.card-container:hover .ui.card.agency-add-card {
  transform: scale(1.1);
}

.ui.card.agency-add-card {
  box-shadow: 12px 12px 20px -6px rgb(180, 179, 179) !important;
  border-radius: 3px !important;
  /* background: #2ef7ed78 !important; */
  transition: all 0.3s;
  border: 1px solid rgb(221, 210, 210) !important;
}

.agency-card-details-container {
  /* display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); */
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-gap: 20px;
}

/* changes start */
@media only screen and (min-width: 768px) {
  .agency-card-details-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .agency-card-details-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Already added */
/* @media screen and (max-width: var(--small-screen)) {
	.grid-container {
		grid-template-columns: repeat(1, 1fr);
	}
  } */

.ui.card.inner-card-agency {
  background-image: linear-gradient(
    to right bottom,
    #b3ffab,
    #12fff7
  ) !important;
  border-radius: 10px !important;
}

.ui.card.agency-side-scroll {
  border-right: 2px solid white !important;
  background: #1a2544 !important;
  /* background-color: rgb(89, 122, 207); */
}

.ui.card.business-logos {
  /* border: 2px solid grey !important;  */
  border-radius: 7px !important;
  background-image: linear-gradient(
    to right bottom,
    #fffef5,
    #d7e9db
  ) !important;
}

.agency-home:hover {
  background-color: rgb(172, 238, 255);
}

/*bootstrap class overight*/

.list-group-item:first-child {
  border-radius: 0 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #212529;
  background-color: #f8f9fa;
}

.dropdown-item:focus,
.dropdown-item:hover {
  outline: none !important;
}

.nav.nav-tabs .nav-item {
  cursor: pointer;
}

.content-area {
  height: calc(100vh - 75px);
  /* overflow-x: auto; */
}

.inputLabel {
  height: 80px;
  line-height: 80px;
}

.ma {
  margin: auto;
}

.list-group-item {
  padding: 0 !important;
  border: none !important;
  margin-bottom: 0 !important;
  background: #00caca !important;
}

.list-group-item > a {
  padding: 5px 15px !important;
  display: block;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
  background: #f8f9fa;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.list-group-item > a:hover {
  text-decoration: unset;
  color: #ec588d;
  background: #edf0f2;
}

.list-group-item .active {
  position: relative;
  display: block;
  color: #ec588d;
  background-color: #edf0f2;
}

/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #00CACA !important;

} */

/* .nav-tabs .nav-item .nav-link.active {
	border-bottom: 2px solid #00CACA;
} */

/*business setup*/

/* .business-setup .nav-tabs .nav-link.active {
	color: #00CACA;
	background-color: transparent;
	border-color: transparent;
	border-bottom: 2px solid #00CACA;
} */

.business-setup .nav-tabs .nav-link:hover {
  /* border-color: transparent;
	border-bottom: 2px solid #00CACA; */
}

.nav.nav-tabs {
  border-radius: 2px !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

/* scroll bar overrite */

::-webkit-scrollbar {
  width: 8px !important;
  height: 7px !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.08) !important;
  background: rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background: rgba(0, 0, 0, 0.1) !important;
}

/*review page css*/

.rating label {
  margin-bottom: 0;
}

.rating {
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 50px;
  display: inline-block;
  margin-right: 8px;
  border: 1px solid #bfc2cb;
  line-height: 1;
  color: var(--fp-secondary-font-dark);
}

.rating-count {
  color: #ffff;
  font-size: 13px;
  font-weight: bold;
  padding-right: 4px;
}

.star-wrap {
  vertical-align: bottom;
}

.h-0 {
  min-height: initial !important;
}

.sticky-header {
  /* position: -webkit-sticky !important;
  position: sticky !important;
  top: 20;
  z-index: 1; */
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f6f6f8;
  padding: 10px 0px;
}

.review-checkbox {
  margin-top: 7px !important;
  margin-left: -7px !important;
}

.review-icon {
  color: #10a210;
  cursor: pointer;
  font-size: 20px;
}

.review-text {
  font-size: 15px;
}

.review-user-icon {
  padding: 7px 9px;
  background: #999;
  margin-right: 10px;
  color: #fff;
}

.ui.search.height-input {
  font-size: 12px;
}

.review-star {
  margin-top: 23px;
}

.ui.button.review-sort-btn {
  background: #ffffff;
  padding: 11px 14px 11px 19px;
  border: 1px solid var(--fp-secondary-outline);
  outline: 0;
  font-size: 13px;
  border-radius: 8px;
}

.review-filter .ui.form input[type="text"] {
  width: auto;
  vertical-align: middle;
}

.input-width {
  width: 115px !important;
  border: 2px solid grey !important;
  text-align: center !important;
}

.error.field .ui.red.pointing.basic.label {
  border: none;
  background: #fff !important;
  font-weight: 500;
  text-align: left;
  display: block;
  margin: 0;
  padding: 0;
  padding-top: 4px;
}

.error.field .ui.basic.pointing.label:before,
.error.field .ui.basic[class*="pointing above"].label:before {
  display: none !important;
}

/* Gaudge Chare */

.fp-gaudge-chart .current-value {
  transform: translateY(10px) !important;
}

.recharts-wrapper {
  width: auto !important;
}

/*sementic-override*/

.ui.teal.button,
.ui.teal.buttons .button {
  background-color: #00caca;
}

.ui.teal.button:hover,
.ui.teal.buttons .button:hover {
  background-color: #00b5ad;
}

.ui.form .field > label {
  font-weight: 400 !important;
}

.ui.smini.five.statistics {
  justify-content: center;
}

.ui.small.statistics .statistic > .value,
.ui.small.statistic > .value {
  font-size: 2rem !important;
}

.ui.smini.statistics .statistic > .value,
.ui.smini.statistic > .value {
  font-size: 1rem !important;
}

.ui.smini.statistics .statistic > .label,
.ui.smini.statistic > .label {
  font-size: 12px !important;
}

.ui.basic.red.label {
  font-size: 11px;
  color: #dc3545 !important;
  font-weight: bold !important;
}

.ui.stacked.segments:before,
.ui.stacked.segments:after,
.ui.stacked.segment:before,
.ui.stacked.segment:after {
  height: 0;
  border-top: none;
}

.i.bordered.icon {
  box-shadow: 0em 0em 0em 0.04em rgba(0, 0, 0, 0.1) inset;
}

.ui.pointing.dropdown > .menu {
  margin: -0.6em 0.5em 0em 0em !important;
}

.ui.message .header:not(.ui) {
  font-size: 1em;
}

.ui.feed,
.ui.cards > .card .meta,
.ui.card .meta {
  font-size: 13px;
}

.ui.input input:not([type]),
.ui.input input[type="date"],
.ui.input input[type="datetime-local"],
.ui.input input[type="email"],
.ui.input input[type="number"],
.ui.input input[type="password"],
.ui.input input[type="search"],
.ui.input input[type="tel"],
.ui.input input[type="time"],
.ui.input input[type="text"],
input.form-control {
  height: 38px !important;
  width: 100%;
  border: 1px solid #bfc2cb;
  position: relative;
  font-size: 13px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; */
  border-radius: 8px;
  background-color: #fff;
}

.ui.input input:focus,
input.form-control:focus,
.fp-textArea:focus,
.comments-textarea__control:focus-within {
  box-shadow: rgba(0, 142, 255, 0.25) 0px 0px 0px 0.2rem !important;
  border-color: rgb(0, 142, 255) !important;
  outline: none;
}

/*multi-select override*/

.Select-input > input {
  font-family: "Outfit", sans-serif !important;
  font-size: inherit !important;
  margin: 0 !important;
  outline: none !important;
  line-height: 17px !important;
  padding: 8px 0 12px !important;
  -webkit-appearance: none !important;
  background: none transparent !important;
  border: 0 none !important;
  box-shadow: none !important;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: normal;
  display: flex;
  align-items: center;
  text-wrap: wrap;
  padding-right: 30px;
}

.Select-input {
  height: 36px;
}

.form-group label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  text-transform: none;
}

.pos-relative {
  position: relative;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 10;
  top: 65px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  max-height: 170px;
  overflow-y: auto;
  width: 100%;
}

.suggestion-item,
.suggestion-item--active {
  cursor: pointer;
  line-height: 1.2;
  padding: 5px 6px;
}

.ui-table-responsive {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100% !important;
  max-width: 100% !important;
}

.table thead th {
  border-top: none;
  padding-bottom: 6px !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.3) !important;
}

.ui.sortable.table thead th {
  border-left: none;
}

.ui.compact.table td {
  padding: 0.7em 0.7em 0.1em;
}

.dropdown-heading {
  color: #ffffff !important;
  background-color: #0e7ec0 !important;
}

.width-md {
  width: 200px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.width-sm {
  width: 130px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.width-md-th {
  width: 200px;
}

.help-icon {
  font-size: 25px !important;
}

.facebook-button button {
  background: #1877f2;
  color: rgb(255, 255, 255);
  border-radius: 190px !important;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif !important;
  text-transform: none;
  padding: 10px 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background ease-in 300ms;
  margin: auto;
}

.google-button {
  display: inline-block;
  background: #ff4b26;
  color: rgb(255, 255, 255);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 16px;
  /* font-weight: bold; */
  font-family: "Outfit", sans-serif !important;
}

.social-google-account {
  background: #ff4b26;
  color: rgb(255, 255, 255);
  border-radius: 190px !important;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif !important;
  text-transform: none;
  padding: 10px 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background ease-in 300ms;
  margin: auto;
}

.filterHead .social-google-account:hover,
.social-google-account:active {
  background: #ff4a26d9 !important;
}

.filterHead .facebook-button button:hover,
.facebook-button button:active {
  background: #1876f2c2 !important;
}

.twitter-button {
  display: inline-block;
  background: #00c8ff;
  color: rgb(255, 255, 255);
  width: 190px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: bold;
  font-family: "Outfit", sans-serif !important;
}

.booking-button {
  display: inline-block;
  background: #02317b;
  color: rgb(255, 255, 255);
  width: 190px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: bold;
  font-family: "Outfit", sans-serif !important;
}

.logo-wrap {
  background: #f8f9fa;
  height: 57px;
  padding: 11px 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.branch-name {
  margin-left: auto !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.fp-card .ui.card .content {
  padding: 16px;
}

.ui.cards > .card,
.ui.card,
.card,
.ui.card .header {
  border-radius: 12px !important;
  box-shadow: none !important;
}

.fp-card.ui.card.fp-card-elevation {
  box-shadow: 0px 2px 30px 0px #0000001a !important;
}

.fp-card-elevation {
  box-shadow: 0px 1px 3px 0px #1018280F !important;
}

.fp-card-primary-elevation {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.review-card-wrapper.social-comments-wrapper {
  border-radius: 8px !important;
}

.card {
  border: none !important;
}

/* dashboard css */
.dashboard-cards {
  margin: 10px 0 !important;
  padding-bottom: 10px;
}

.dash-card {
  min-height: 415px !important;
}

.dash-card header,
.dash-card .heading {
  font-size: 1.2rem;
}

.dash-card-lg {
  min-height: 350px !important;
}

.dash-card-chart {
  min-height: 310px !important;
}

.dash-card-chart .c3-chart-arcs text {
  display: none;
}

.dash-sms-card {
  margin-left: 8px !important;
}

.dash-card-heading {
  font-weight: bold;
  font-size: 1.28em;
  margin-top: -0.21em;
  line-height: 1.28em;
  font-family: "Outfit", sans-serif !important;
  color: rgba(0, 0, 0, 0.85);
}

.review-channel-performance .channel-list {
  align-items: center;
}

.review-channel-performance .channel-list {
  width: 170px;
}

.review-channel-performance .channel-list .ui.statistic:first-child {
  margin-left: 0 !important;
}

.review-channel-performance .channel-icon {
  max-height: 70px !important;
  height: 70px;
}

.review-channel-performance .scroll-menu-arrow {
  font-size: 28px;
  color: #969696;
}

.review-channel-performance .menu-item-wrapper:focus {
  outline: none !important;
}

.ui.search.dropdown {
  min-width: 100% !important;
}

.flatpickr-input,
.select-range {
  border: 1px solid #bfc2cb !important;
  border-radius: 8px !important;
  line-height: 1em;
  padding: 9px 8px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1em;
}

.select-range {
  padding: 10px 8px;
  width: 100%;
}

.select-range .dropdown.icon {
  float: right;
}

.box-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.ui.multiple.dropdown > .label {
  font-weight: 400;
  font-size: 12px;
}

.ui.multiple.dropdown.fp-multi-label > .label {
  background-color: #ddeff0 !important;
  color: #1b1c1d;
  border-radius: 99px;
  box-shadow: unset !important;
  line-height: 1;
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 12px;
  gap: 10px;
  min-width: 160px;
  font-family: "Outfit" !important;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
}

.fp-multi-label i.icon.delete {
  margin: 0px;
  width: auto;
  height: auto;
  display: inline-flex;
  opacity: 1;
}

.fp-multi-label i.icon.delete:before {
  content: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' transform='translate(0 0.5)' fill='white' fill-opacity='0.01' style='mix-blend-mode:multiply'/%3E%3Cpath d='M8 1.5C4.1 1.5 1 4.6 1 8.5C1 12.4 4.1 15.5 8 15.5C11.9 15.5 15 12.4 15 8.5C15 4.6 11.9 1.5 8 1.5ZM10.7 12L8 9.3L5.3 12L4.5 11.2L7.2 8.5L4.5 5.8L5.3 5L8 7.7L10.7 5L11.5 5.8L8.8 8.5L11.5 11.2L10.7 12Z' fill='%23646565'/%3E%3C/svg%3E%0A");
}

.review-time {
  width: auto !important;
}

.dash-review-site .content {
  display: inline;
  padding-left: 5px;
  vertical-align: text-bottom;
}

.box-shadow-none {
  box-shadow: none !important;
  border-radius: none !important;
}

.side-filter .col-md-4 {
  max-width: 100%;
  flex: 100%;
  margin-right: 15px;
  margin-bottom: 10px;
}

.side-filter .date-drop-down {
  width: 100%;
  margin-bottom: 10px;
}

.side-filter .input-width {
  width: 100% !important;
}

.side-filter button {
  margin-top: 5px !important;
}

.side-filter .ml-1,
.mr-1 {
  margin: 0px !important;
}

.print-button {
  position: absolute;
  top: 8px;
  right: 6px;
  cursor: pointer;
}

@media print {
  .print-hide {
    display: none;
  }

  .print-width {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.terms-checkbox {
  display: inline-flex;
  margin-bottom: 8px;
}

.terms-checkbox .hidden {
  display: inline-flex !important;
  z-index: 3 !important;
}

.terms-checkbox span {
  padding-left: 22px;
}

.terms-checkbox .ui.checkbox input[type="checkbox"] {
  opacity: 1 !important;
}

.react-phone-number-input__icon {
  width: 1.9em;
  height: auto;
  border: none;
}

.react-phone-number-input__input {
  height: 38px;
  padding-left: 55px !important;
}

.react-phone-number-input__country--native {
  position: absolute;
  padding-left: 10px;
  align-self: center;
}

select.react-phone-number-input__country-select {
  opacity: 0 !important;
}

.required-field:after {
  display: inline-block;
  vertical-align: top;
  margin: -0.2em 0em 0em 0.2em;
  content: "*";
  color: #db2828;
}

.business-logo {
  height: 35px !important;
  margin-right: 8px;
}

/* Side-nav css starts from here */

.logo-avatar-container {
  /* width: 2em;
  height: 2em; */
  position: relative;
  /* margin: 0px 8px 0px 0px; */
}

.logo-avatar-container .overlay {
  display: none;
}

.logo-avatar-container:hover .overlay {
  position: absolute;
  top: 0;
  display: block;
  width: 25px;
  height: 25px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 500rem;
  transition: background 0.5s ease;
  color: #fff;
  padding: 7px 6px;
  line-height: 11px;
  cursor: pointer;
}

.items-wrap {
  align-items: center;
  display: flex;
  padding: 4.5px 0px;
}

.account-mngr-card {
  position: absolute;
  width: auto !important;
  padding: 8px 8px !important;
  bottom: 0;
  -webkit-transform: none !important;
  transform: none !important;
  background: #007ec0 !important;
  cursor: default !important;
  box-shadow: none !important;
}

.account-mngr-card:hover {
  background: #007ec0 !important;
}

.account-mngr-card .side-bar-icon {
  margin-left: 0;
}

.account-mngr-card .header {
  font-weight: bold;
  font-size: 14px;
  color: #fff !important;
}

.account-mngr-card .meta,
.account-mngr-card a {
  color: #fff !important;
}

/*Net score card css starts here*/
.nps-stats svg {
  overflow: initial !important;
}

.nps-stats .ui.label:first-child {
  background: rgb(224, 50, 50);
  margin-right: 10px;
  margin-left: 10px;
  box-shadow: 2px 2px 6px rgba(224, 50, 50, 1);
  color: white;
}

.nps-stats .ui.label:nth-child(2) {
  background-color: rgb(241, 241, 44);
  margin-right: 10px;
  box-shadow: 2px 2px 6px rgba(241, 241, 44, 1);
  color: white;
}

.nps-stats .ui.label:nth-child(3) {
  background-color: rgb(148, 212, 63);
  box-shadow: 2px 2px 6px rgba(148, 212, 63, 1);
  margin-right: 10px;
  color: white;
}

.nps-stats .ui.label:nth-child(4) {
  background-color: rgb(28, 136, 46);
  box-shadow: 2px 2px 6px rgba(28, 136, 46, 1);
  margin-right: 10px;
  color: white;
}

/*Template css starts here*/

.review-methods .review-img-inactive {
  margin-right: 5px;
  padding: 2px;
  /* border: 2px solid rgba(0, 0, 0, 0.1) !important; */
}

.review-methods .review-img-active {
  margin-right: 5px;
  padding: 2px;
  /* border: 2px solid #00B5AD !important; */
}

.review-select-radio label {
  color: var(--secondary-blue-gray, #7a8193) !important;
}

/*Newsletter css*/
.nw-header-container {
  width: 600px;
  position: relative;
  margin: 0 auto;
}

.nw-header-container .overlay {
  display: none;
}

.nw-header-container:hover .overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: background 0.5s ease;
  color: #fff;
  padding: 7px;
  line-height: 14px;
}

.nw-header-avatar {
  width: 600px;
  margin-top: 6px;
  display: inline-block;
}

.nw-avatar-edit {
  margin-left: 200px;
  font-size: 30px;
}

/*Feedback page css starts here*/

.feedback-success {
  font-size: 15px;
}

.feedback-success .title-text {
  font-size: 17px;
}

.feedback-success .copy-review-container {
  background: lightgray;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 4px;
}

.feedback-success .custom-textArea {
  resize: none;
  min-height: 100px;
  width: 100%;
  border-radius: 25px;
  padding: 20px;
  font-size: 13px;
  height: 120px;
  border: 2px solid rgb(183, 64, 131);
  font-style: italic;
}

.feedback-success .custom-textArea:focus {
  outline: none;
}

.recommendation-text {
  font-size: 16px;
  font-weight: 500;
}

.social-btn-group .ui.card {
  box-shadow: none !important;
}

.template-bg {
  opacity: 1;
  background: rgb(44, 46, 38)
    url(https://images.typeform.com/images/88dwhLUaSKWH/background/large);
  margin: auto;
  padding: 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.outer-div {
  height: 100%;
  width: 100%;
}

.feedback-already-exist .feedback-pink-bg {
  background: #fb008e;
  background-image: linear-gradient(to top, #fb008e, #fdcce8);
  box-shadow: 0 4px 1px 0 #bbc0b6;
}

.feedback-already-exist .feedback-title {
  background: #2cc9c9;
  border-radius: 40px;
  border: 1.2px solid #fee7fb;
  margin: 0;
  color: white;
  font-size: 16px;
}

.feedback-already-exist .recommendation-text {
  font-size: 16px;
  font-weight: 500;
}

/*Profile form css starts here*/
.user-profile-container {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;
}

.user-profile-container .overlay {
  display: none;
}

.user-profile-container:hover .overlay {
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px 0px 50px 50px;
  transition: background 0.5s ease;
  color: #fff;
  padding: 7px;
  line-height: 14px;
  cursor: pointer;
}

.profile-form .pic-upload-info {
  background: #f1f8ff;
  border-color: #1667d4 !important;
  font-size: 13px;
}

.profile-form .user-profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 500rem;
  display: inline-block;
}

.remove-btn {
  align-items: center;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin-right: 8px;
  width: 15px;
  font-size: 16px;
}

/*Common css starts here*/

.text-capitalize {
  text-transform: capitalize;
}

.has-text-italic {
  font-style: italic;
}

/*Desktop specific css starts here*/

@media screen and (min-width: 1200px) {
  .feedback-success .copy-review-btn {
    position: absolute;
    width: fit-content;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/*Mobile specific css starts here*/

@media screen and (max-width: 768px) {
  .feedback-form .review-method-img {
    width: 45px;
  }

  .feedback-success .copy-review-btn {
    margin-top: 1em;
  }

  .dashboard-cards .pl-0 {
    padding-left: 15px !important;
  }

  .dashboard-cards .pr-0 {
    padding-right: 15px !important;
  }

  .sm-mr-15 {
    margin-left: 15px;
  }

  .sidenav-wrap {
    min-height: 64vh !important;
  }

  .print-button {
    display: none;
  }
}

/*Register form css*/

.register-form .ui.form .field:last-child,
.register-form .ui.form .fields:last-child .field {
  margin: 0em 0em 1em;
}

.location-header {
  background: #58cbcb;
  padding: 7px 12px 6px !important;
  padding-top: 4px;
  text-align: center;
  font-weight: 400;
  font-size: 1.25rem;
  color: #fff;
  margin-top: 0;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.review-status-dropdown {
  padding: 5px !important;
  height: 25px !important;
  display: inline-block !important;
  margin-right: 10px !important;
  top: 2px;
  position: relative;
}

.review-status-dropdown .text {
  font-size: 13px !important;
}

.review_status_btn {
  padding-top: 5px !important;
  height: 25px !important;
  display: inline-block !important;
  margin-left: 75px !important;
}

.review_status_btn .text {
  font-size: 13px !important;
}

.status-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.8;
}

.remove-card {
  box-shadow: none !important;
  background-color: transparent !important;
}

.status-dot:hover {
  opacity: 1;
  cursor: pointer;
}

.rank-circle {
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
  background-color: #10c93f !important;
  color: #fff;
  text-align: center;
  font-weight: 600;
}

.rank-circle-1 {
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
  background-color: #e64616 !important;
  color: #fff;
  text-align: center;
  font-weight: 600;
}

.rank-circle-blue {
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
  background-color: #3a96d3 !important;
  color: #fff;
  text-align: center;
  font-weight: 600;
}

.table-circle {
  height: 15px;
  width: 15px;
  font-size: 95%;
}

.toploc-circle {
  height: 22px;
  width: 22px;
  font-size: 110%;
}

.filter-section .form-group {
  margin-bottom: 0;
}

.filter-section label {
  display: none;
}

.filter-section .col {
  padding: 0 5px;
}

.filter-section .Select-value {
  background-color: transparent !important;
  border: none;
}

.filter-section .Select-value .Select-value-label {
  padding-left: 0;
  padding-right: 2px;
}

.filter-section .Select-value-icon {
  display: none;
}

.filter-section .Select--multi .Select-multi-value-wrapper {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  width: 115px;
}

.Select-input {
  height: 34px;
}

.ui.dropdown > .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.businessCategory .ui.dropdown > .text {
  font-weight: 590;
}

.ui.search.dropdown {
  padding-top: 11px;
  padding-bottom: 6px;
}

.ui.dropdown,
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  font-size: 13px;
}

.ui.dropdown {
  line-height: 14px;
}

.support-icon .ui.dropdown > .dropdown.icon {
  margin-left: 1px !important;
}

.date-select {
  position: relative;
}

.other-date {
  left: 0;
  background: #fff;
  z-index: 9;
  width: 290px;
  /* padding: 8px; */
}

.side-filter .other-date {
  width: auto;
}

.to-text {
  font-weight: bold;
  min-width: 25px;
  display: inline-block;
  text-align: center;
}

.side-filter .to-text {
  display: block;
  text-align: center;
}

.filter-item {
  width: 20%;
  margin-right: 8px;
}

.filter-item i.icon.dropdown:before {
  content: "" !important;
  border: solid #333;
  border-width: 0 1px 1px 0;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  right: 3px;
  top: 2px;
  position: absolute;
}

.filter-item .ui.search i.icon.dropdown:before {
  right: 12px;
  top: 11px;
}

.filter-item .Select-arrow {
  border: solid #333 !important;
  border-width: 0 1px 1px 0 !important;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  right: 11px;
  top: 11px !important;
  position: absolute;
}

.filter-btn {
  width: 25%;
}

.reviews-width {
  margin-right: 8px;
}

.ui.dropdown.selected,
.ui.dropdown .menu .selected.item,
.Select-option.is-selected {
  background: rgb(88, 203, 203);
  color: #fff;
  font-weight: 500;
}

.tab-dropdown .ui.dropdown {
  line-height: 0;
}

.content-break {
  word-break: break-word;
}

.wordcloud-table {
  margin-top: 20;
}

.wordcloud-table-width {
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wordcloud-label {
  margin: 2px !important;
}

.locationTable tr td {
  /* margin-top: 10px !important; */
}

.ui.table tr:first-child td {
  /* border-top: none; */
  /* margin-top: 10px !important; */
}

.expand-nav-btn {
  position: absolute !important;
  bottom: 3px;
  width: inherit;
  cursor: pointer;
}

/*Complaints Page Css startss here*/
.complaint-border {
  border-left: 3px solid #30272a !important;
}

.button-hover:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
}

/*Central Insights Form Css starts from here */
.category-word {
  display: inline-block;
  margin-right: 5px;
  background: rgba(242, 249, 249, 1);
  border-radius: 10px;
  padding: 2px 5px;
  opacity: 0.8;
  margin-top: 5px;
  color: rgba(67, 142, 150, 1);
}

.tag-list {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  padding: 5px;
}

.tag-list li {
  margin-right: 5px;
  list-style: none;
  background-color: #c0e0fb;
  padding: 2px 4px 5px 5px;
  border-radius: 20px;
  margin-top: 5px;
  border: 1px solid lightskyblue;
  animation: fadeInList 0.5s;
}

@keyframes fadeInList {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.tag-list li button {
  align-items: center;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 6px;
  transform: rotate(45deg);
  width: 15px;
  font-size: 16px;
  font-weight: bold;
}

.input-tag input {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  display: inline-block;
  padding: 0 18px;
  box-sizing: border-box;
  border: 1px solid rgb(75, 67, 67);
  outline: none;
}

.input-tag input:focus {
  border: 1.4px solid lightskyblue;
}

.recommended_keyword {
  margin: 0 8px 9px 0;
  display: inline-block;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  background-color: #f3f3f3;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  box-shadow: 1px 1px 6px #d3d3d3;
  transition: ease-in-out 0.2s;
}

.recommended_keyword:hover {
  transform: scale(1.07);
}

.keyword_sentiment {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px 0 0;
}

/*----------Insights wordCloud styles----------*/

.bold-heading {
  font-weight: 500;
  letter-spacing: 1px;
}

.line-top {
  display: flex;
  border-top: 1px solid rgba(128, 128, 128, 0.801);
}

.positive {
  /* color: #66df16 ; */
  color: rgb(87, 179, 11);
}

.negative {
  /* color: red; */
  color: rgb(230, 59, 33);
}

.neutral {
  /* color: orange; */
  color: rgb(241, 155, 16);
}

.circle-icon {
  transition: ease-in-out 1s;
}

/*-----------Insights Location Grading Styles----------*/
.grading-view {
  border-radius: 12px;
  text-align: center;
  padding: 2px;
  width: 40px;
  display: inline-block;
}

.grading {
  border-radius: 5px;
  text-align: center;
  padding: 2px 0;
  width: 26px;
  font-weight: 600;
  color: white;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  /* box-shadow: 2px 2px 6px palevioletred; */
}

.grading:hover {
  transform: scale(1.1);
  cursor: default;
}

.width-insights {
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.width-insights-th {
  width: 200px;
}

.grade1 {
  background: #db0000;
}

.grade2 {
  background: #f23000;
}

.grade3 {
  background: #e65c00;
}

.grade4 {
  background: #d98200;
}

.grade5 {
  background: #c0c000;
}

.grade6 {
  background: #8fb300;
}

.grade7 {
  background: #3d9900;
}

.grade8 {
  background: #1c8d00;
}

.dgrade {
  transform: translateY(7px);
  width: 28px;
  height: 25px;
  border: 0.5px solid #fff;
  border-radius: 4px;
  box-shadow: inset 1px 1px 2px #fff, inset -1px -1px 2px #f3f3f3;
  padding: 2px;
}

.grade0 {
  background: #d1d1d1;
}

.col.grade {
  padding: 0;
  color: white;
  text-align: center;
  font-weight: 500;
  display: flex;
  justify-content: space-evenly;
}

.grade-align {
  height: 37px;
  line-height: 37px !important;
  font-size: 13px;
}

.row.grade-row {
  margin: 0;
}

.ratingCol {
  text-align: center;
}

.ratingCol p {
  margin-bottom: 2px;
}

/*-------------Insights Topics Style---------------------*/
.showMore_btn {
  align-items: center;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  color: #1b1c1d;
  padding: 5px 2px;
  font-size: 13px;
  text-transform: capitalize;
}

.showMore_btn .expand-icon {
  width: 10px;
  height: 10px;
  margin: 0px 0px 0px 6px;
}

.showMore_btn .expand-icon.less {
  margin: 0px 0px 0px 6px;
  transform: rotate(180deg);
}

div.row.segment-width {
  margin-right: 0;
}

.red-label {
  position: absolute;
  top: 30%;
  width: max-content;
  padding: 2px !important;
  font-size: 9px !important;
  animation: red-label 1s infinite;
}

@keyframes red-label {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

.ui.segment.full-review {
  position: relative;
  background: rgb(246, 246, 248);
  font-size: 12px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: none;
  color: #7a8193;
  border: unset;
  font-family: "Outfit", sans-serif !important;
}

.nounContainer {
  overflow: auto;
  max-height: 300px;
  width: 400px;
  background-color: #f6f6f8 !important;
  padding: 5px !important;
  box-shadow: none !important;
  border: none !important;
  scrollbar-gutter: stable;
}

.nounContainercompletionstatus {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  width: 400px;
  padding: 5px !important;
  box-shadow: none !important;
  border: none !important;
  scrollbar-gutter: stable;
}

.bold-reviewer {
  font-weight: 700;
  color: grey;
  letter-spacing: 1px;
}

.date {
  font-weight: 400;
  color: grey;
  letter-spacing: 1px;
}

.bold-branch {
  font-weight: 500;
  font-size: 13px;
  color: dimgray;
  margin-bottom: 4px;
}

.underline {
  border-bottom: 3px solid #f3f3f3;
}

.ui.fluid.card.review-shadow {
  box-shadow: 3px 3px 10px #d3d3d3e1, -5px -5px 10px #f8f6f6 !important;
  border-radius: 10px !important;
  background-image: linear-gradient(45deg, transparent, #f6f6f6);
}

.ui.fluid.card.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ui.card.primary-overview-card {
  background-image: linear-gradient(
    to right bottom,
    rgb(0, 202, 202, 0.2),
    rgb(255, 0, 144, 0.2)
  );
}

/*SideNav lock styles starts from here*/
.sidenav-lock {
  float: right;
  color: #fff !important;
  padding: 2px;
}

.collapsed {
  font-size: 8px;
}

.ui.selection.dropdown.location-filters .menu > .item {
  padding-right: 2px !important;
  white-space: nowrap;
  overflow-x: auto;
}

.ui-text {
  white-space: nowrap;
}

.ui.dropdown.location-filter-dropdown > .text {
  width: auto;
  overflow: inherit;
}

.ui.dropdown.location-filter-dropdown {
  background: white;
  padding: 10px;
}

.ui.inline.dropdown .dropdown.icon {
  margin: -8.755px !important;
}

/* Reviews customer-care form css stats from here  */
.customer-margin {
  margin-left: 60px;
}

/* Mentions css */

.mention-actions {
  transition: ease-in-out 0.2s;
}

.mention-actions:hover {
  transform: scale(1.2);
}

.reviews-tag-button {
  padding: 7px 14px;
  background: #2d85c8;
  border-radius: 5px;
  color: white;
  height: 25px !important;
  padding-top: 2px;
}

.align-center {
  display: flex;
  align-items: center;
}

.ui.dropdown.mentions-sentiment {
  padding: 10px;
}

.ui.dropdown.mentions-sentiment:hover {
  border-radius: 7px;
  box-shadow: inset 2px -2px 5px #ededed, inset -2px 2px 5px #ededed;
  background-image: linear-gradient(45deg, transparent, #f6f6f6);
}

.mentions-fb-button {
  height: 2.5em;
  background: #5a76b1;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 12px;
}

.mentions-twt-button {
  height: 2.5em;
  background: #00c8ff;
  border: none;
  border-radius: 5px;
  color: white;
}

.mentions-sidefilters {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 2.8rem;
  z-index: 1;
}

/* Table.js page css  */

.location {
  text-align: center !important;
  font-size: 12px;
}

.ui.dropdown.order-by-mentions {
  border: 1px solid darkgrey;
  display: inline-flex;
  border-radius: 7px;
  /* flex-direction: row-reverse; */
}

.ui.dropdown.order-by-mentions.select-range {
  line-height: 1;
  width: 100px !important;
  padding: 8px !important;
  height: 32px;
  /* max-height: 30px; */
  min-height: unset !important;
}

.clear-mentions {
  color: gray;
  text-decoration: underline;
  transition: ease-out 0.2s;
}

.clear-mentions:hover {
  transform: scale(1.2);
}

.images-mentions {
  border-radius: 4px;
  transition: ease-out 0.2s;
  /* box-shadow: 2px 2px 20px 10px rgb(221, 221, 221); */
}

.images-mentions:hover {
  transform: scale(1.1);
}

.center-position {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ui.dropdown.dropdown-absolute,
.ui.dropdown.dropdown-absolute > .icon {
  position: absolute;
}

.tags-item {
  padding-top: 0.7em;
  padding-bottom: 0.7em;
}

.tags-list,
.tags-list-reviews {
  border-bottom: 1px solid grey;
}

.tags-highlight-reviews {
  color: #4267b2;
}

.tags-highlight {
  color: #ef3d86;
}

.tags-list:hover,
.tags-list-reviews:hover {
  background: rgb(243, 243, 243);
}

.tags-list:hover > .tags-item {
  color: #ef3d86;
}

.tags-list-reviews:hover > .tags-item {
  color: #4267b2;
}

.fab.fa-twitter.twt:hover {
  color: #1da1f3 !important;
}

.far.fa-copy.cpy:hover {
  color: rgb(243, 243, 138) !important;
}

.fab.fa-facebook.fb:hover {
  color: #4267b2 !important;
}

.fab.fa-whatsapp.wtsp:hover {
  color: #4fce5d !important;
}

.mention-tag {
  /* padding-bottom: 1em; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.filter-tags {
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.filter-tags:hover > * {
  color: #ef3d86 !important;
}

/* tickets css start from here  */
.ml-large {
  margin-left: 4em !important;
}

.review-border-blue {
  border-left: 3px solid #12488c !important;
  margin-top: 5px !important;
}

.teal {
  background-color: teal !;
}

.blue {
  background-color: #12488c !important;
}

.blue:active,
.blue:hover {
  background-color: #ec588d !important;
}

.ticketing-btn:hover,
.ticketing-btn:active {
  border: 1px solid #12488c !important;
  border-radius: 3px !important;
}

.ticketing-btn {
  height: auto;
  width: 80px;
  background-color: Transparent !important;
  border-style: none !important;
  color: #12488c !important;
  font-family: "Outfit", sans-serif !important;
  font-size: 15px;
  margin-right: 5px;
}

.ticketing-btn-blue {
  height: auto;
  width: 80px;
  background-color: #12488c;
  border-style: none;
  color: white;
  font-family: "Outfit", sans-serif !important;
  font-size: 15px;
  padding: 5px;
  border-radius: 3px;
}

.ticket-activity-btn {
  background-color: transparent !important;
  position: relative !important;
  top: -0.7px !important;
  font-family: "Outfit", sans-serif !important;
  color: #2185d0 !important;
  height: 25px !important;
  display: inline-block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 10px !important;
  margin-top: 0px !important;
  box-shadow: 0px 0px 0px 1px #2185d0 inset !important;
  padding: 0px 20px 0px 20px !important;
}

.ticket-activity-btn-disabled {
  background-color: transparent !important;
  position: relative !important;
  top: -0.7px !important;
  font-family: "Outfit", sans-serif !important;
  color: #c0c0c0 !important;
  height: 25px !important;
  display: inline-block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 10px !important;
  margin-top: 0px !important;
  box-shadow: 0px 0px 0px 1px #c0c0c0 inset !important;
  padding: 0px 20px 0px 20px !important;
}

.ticket-save-btn {
  position: relative !important;
  float: right !important;
  background-color: #12488c !important;
  color: white !important;
  border-radius: 50px !important;
  font-weight: 450 !important;
}

.ticket-save-btn:active,
.ticket-save-btn:hover {
  background-color: #ec588d !important;
}

.ticket-new-width {
  width: 150px;
  display: inline-table !important;
  margin: 5px 5px 5px 5px !important;
  position: relative;
  top: -2px;
}

.ticket-new-width-two {
  position: relative !important;
  width: 150px !important;
  display: inline-table !important;
  margin: 5px 5px 5px 5px !important;
  top: -2px;
}

.ticket-details {
  color: #989898 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  margin: 0 5px 5px 5px !important;
  padding: 2px !important;
  letter-spacing: normal !important;
  text-transform: capitalize;
}

.modal-title {
  width: 100%;
}

.modal-title > .fa {
  cursor: pointer;
  font-size: 1em;
}

.modal-title > .fa:hover {
  font-size: 1.1em;
}

.selected-tag {
  color: #f13a88 !important;
}

.reviews-tags-enter {
  opacity: 0.01;
  transform: scale(0);
}

.reviews-tags-appear {
  opacity: 0.01;
  transform: scale(0);
}

.reviews-tags-appear.reviews-tags-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 700ms, transform 700ms;
}

.reviews-tags-enter.reviews-tags-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 700ms, transform 700ms;
}

.reviews-tags-leave {
  opacity: 1;
  transform: scale(1);
}

.reviews-tags-leave.reviews-tags-leave-active {
  opacity: 0.01;
  transform: scale(0);
  transition: opacity 700ms, transform 700ms;
}

.manage-tags-enter {
  opacity: 0.01;
  height: 0%;
}

.manage-tags-appear {
  opacity: 0.01;
  height: 0%;
}

.manage-tags-appear.manage-tags-appear-active {
  opacity: 1;
  height: 100%;
  transition: opacity 1s, height 1s;
}

.manage-tags-enter.manage-tags-enter-active {
  opacity: 1;
  height: 100%;
  transition: opacity 1s, height 1s;
}

.manage-tags-leave {
  opacity: 1;
  height: 100%;
}

.manage-tags-leave.manage-tags-leave-active {
  opacity: 0.01;
  height: 0%;
  transition: opacity 1s, height 1s;
}

.contact-tags-form {
  border: 1px solid #dadada;
  background: #f7fdfe;
}

.contact-tag {
  padding: 0.1em 0.5em;
  background: aliceblue;
  cursor: pointer;
  border: 1px solid #d1d1d1;
}

.contact-tag-item {
  font-size: 14px;
  color: rgba(67, 142, 150, 1);
  /* height: 50px; */
  position: relative;
  overflow-x: hidden;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.contact-list-tags {
  background-color: #f0f2f5;
  text-transform: capitalize;
  color: #358cfe;
  padding: 3px;
  font-weight: 700;
  cursor: default;
}

.contact-list-tags > i {
  color: #596780;
  cursor: pointer;
}

.selected-contact-tag .tag-text-view {
  background-color: rgba(67, 142, 150, 1) !important;
  color: rgba(242, 249, 249, 1) !important;
}

.contact-tag-item.editing-tag input.edit {
  display: block;
  width: 120px;
  opacity: 1;
  /* height: 50px; */
  padding: 5px;
  /* border-bottom: 1px solid #d3d3d3 !important; */
  border-radius: 3px;
  outline: none;
  border: none;
}

.contact-tag-item.editing-tag label {
  position: absolute;
  font-size: 12px;
  font-weight: normal;
  width: 120px;
  /* height: 50px; */
  left: 0;
  bottom: 0;
  pointer-events: none;
  border-bottom: 1px solid black;
}

.contact-tag-item.editing-tag .tag-text-view,
.contact-tag-item.editing-tag .add-tag-view {
  display: none;
}

.contact-tag-item .tag-text-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;
  line-height: 16px;
  padding: 0.1em 1em;
  background: rgba(242, 249, 249, 1);
  cursor: pointer;
  border-radius: 50px;
}

.contact-tag-item .tag-text-view > i {
  margin-top: 3px;
}

.contact-tag-item .add-tag-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  color: rgba(122, 129, 147, 1);
  cursor: pointer;
}

.contact-tag-item .edit,
.contact-tag-group input.edit {
  display: none;
}

.contact-tag-group.editing-tag-group .tag-group-text {
  display: none;
}

.contact-tag-group {
  /* font-size: x-large; */
  /* height: 55px; */
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.contact-tag-group .cancel-edit-group {
  display: inline-block;
  position: absolute;
  left: 30%;
  bottom: 10%;
  margin-left: 20px;
}

.contact-tag-group.editing-tag-group input.edit {
  display: block;
  width: 30%;
  height: 50px;
  padding: 0px 10px;
  border: none;
  font-size: 16px;
  outline: none;
}

.contact-tag-group.editing-tag-group input.edit.error + .label-name::after,
.contact-tag-item.editing-tag input.edit.error + .tag-name-label::after {
  border-bottom: 3px solid red;
  opacity: 0.7;
}

.contact-tag-group.editing-tag-group label {
  position: absolute;
  font-size: 16px;
  font-weight: normal;
  width: 70%;
  height: 50px;
  left: 0;
  bottom: 0;
  pointer-events: none;
  border-bottom: 1px solid black;
}

.contact-tag-group.editing-tag-group .label-name::after,
.contact-tag-item.editing-tag .tag-name-label::after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  bottom: -1px;
  position: absolute;
  transform: translateX(-100%);
  transition: all 0.4s ease;
}

.contact-tag-item.editing-tag .tag-name-label::after {
  border-bottom: 3px solid rgba(0, 181, 173, 1);
}

.contact-tag-group.editing-tag-group .label-name::after {
  border-bottom: 3px solid rgba(0, 181, 173, 1);
}

.content-name,
.tag-name-content {
  position: absolute;
  left: 0;
  bottom: 5px;
  transition: transform 0.4s ease;
}

.contact-tag-item.editing-tag
  input.edit:focus
  + .tag-name-label
  .tag-name-content,
.contact-tag-item.editing-tag
  input.edit:valid
  + .tag-name-label
  .tag-name-content {
  transform: translateY(-100%);
  color: #6eb4dd;
  font-size: 13px;
}

.contact-tag-group.editing-tag-group
  input.edit:focus
  + .label-name
  .content-name,
.contact-tag-group.editing-tag-group
  input.edit:valid
  + .label-name
  .content-name {
  transform: translateY(-130%);
  color: rgba(0, 181, 173, 1);
  font-size: 14px;
}

.contact-tag-group.editing-tag-group input.edit:focus + .label-name::after,
.contact-tag-group.editing-tag-group input.edit:valid + .label-name::after,
.contact-tag-item.editing-tag input.edit:focus + .tag-name-label::after,
.contact-tag-item.editing-tag input.edit:valid + .tag-name-label::after {
  transform: translateX(0%);
}

.dropdown-select {
  padding: 10px;
  width: 100%;
}

.dropdown-select > .text {
  width: 95% !important;
}

.dropdown-select > .menu {
  overflow: scroll;
  max-height: 200px;
}

.dropdown-select > .dropdown.icon {
  float: right;
  margin-top: 4px !important;
}

#contact-extra-filters {
  min-width: 600px;
  /* background: white; */
}

#contact-extra-filters .Select--multi .Select-multi-value-wrapper {
  height: 30px;
  overflow: hidden;
}

#contact-extra-filters
  .Select--multi
  .Select-multi-value-wrapper
  .Select-value-icon {
  display: none;
}

#contact-extra-filters
  .Select--multi
  .Select-multi-value-wrapper
  .Select-value-label {
  font-size: 14px;
}

.nps-method-circles {
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 99px;
  font-weight: 900;
  color: var(--fp-secondary-font-dark);
  border: 2px solid;
}

.nps-method-circles.large-circles {
  width: 38px;
  height: 38px;
}

.nps-score :nth-child(n) .nps-score-inactive {
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.nps-score .nps-container {
  display: inline-block;
}

.nps-score .nps-score-active {
  border: transparent;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .nps-score .nps-container {
    display: flex !important;
  }

  .nps-score .nps-method-circles {
    width: 25px;
    height: 25px;
    margin-bottom: 1em;
    border-radius: 99px;
    color: black;
    font-size: 15px;
    font-weight: normal;
  }

  .nps-score :nth-child(1).nps-container .nps-method-circles:after {
    content: "-Not Likely";
    display: block;
    font-size: inherit;
  }

  .nps-score :nth-child(10).nps-container .nps-method-circles:after {
    content: "-Extremely Likely";
    display: block;
    font-size: inherit;
  }
}

.nps-score :nth-child(1) .nps-score-active {
  background-color: var(--fp-red);
}

.nps-score :nth-child(2) .nps-score-active {
  background-color: var(--fp-red);
}

.nps-score :nth-child(3) .nps-score-active {
  background-color: var(--fp-red);
}

.nps-score :nth-child(4) .nps-score-active {
  background-color: var(--fp-red);
}

.nps-score :nth-child(5) .nps-score-active {
  background-color: var(--fp-red);
}

.nps-score :nth-child(6) .nps-score-active {
  background-color: var(--fp-red);
}

.nps-score :nth-child(7) .nps-score-active {
  background-color: var(--fp-amber);
  color: var(--fp-secondary-font-dark);
}

.nps-score :nth-child(8) .nps-score-active {
  background-color: var(--fp-amber);
  color: var(--fp-secondary-font-dark);
}

.nps-score :nth-child(9) .nps-score-active {
  background-color: var(--fp-parrotGreen);
  color: var(--fp-secondary-font-dark);
}

.nps-score :nth-child(10) .nps-score-active {
  background-color: var(--fp-parrotGreen);
  color: var(--fp-secondary-font-dark);
}

/* Normal */
.nps-score :nth-child(1) .nps-method-circles {
  border-color: var(--fp-red);
}

.nps-score :nth-child(2) .nps-method-circles {
  border-color: var(--fp-red);
}

.nps-score :nth-child(3) .nps-method-circles {
  border-color: var(--fp-red);
}

.nps-score :nth-child(4) .nps-method-circles {
  border-color: var(--fp-red);
}

.nps-score :nth-child(5) .nps-method-circles {
  border-color: var(--fp-red);
}

.nps-score :nth-child(6) .nps-method-circles {
  border-color: var(--fp-red);
}

.nps-score :nth-child(7) .nps-method-circles {
  border-color: var(--fp-amber);
}

.nps-score :nth-child(8) .nps-method-circles {
  border-color: var(--fp-amber);
}

.nps-score :nth-child(9) .nps-method-circles {
  border-color: var(--fp-parrotGreen);
}

.nps-score :nth-child(10) .nps-method-circles {
  border-color: var(--fp-parrotGreen);
}

.chart-list-legend {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.likeliness-right-position {
  position: absolute;
  right: calc((100% - 520px) / 2);
  top: 15px;
}

.likeliness-labels .likeliness-left-position {
  position: absolute;
  left: calc((100% - 520px) / 2);
  top: 15px;
}

.mentions-chart-container {
  height: 50vh;
  width: 60vw;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.likeliness-labels {
  display: flex;
  font-size: 14px;
}

@media only screen and (max-width: 1024px) {
  [class*="likeliness-labels"] {
    display: none;
  }
}

/*User Tagging in Comments*/

.fp-attachment-icon {
  position: absolute;
  z-index: 1;
  right: 5px;
  bottom: 0px;
}

.comments-textarea {
  margin: 0;
}

.comments-textarea--singleLine .comments-textarea__control {
  border-radius: 0.3rem;
  display: inline-block;
}

.comments-textarea--singleLine .comments-textarea__higlighter {
  padding: 1px;
  border: 2px inset transparent;
}

.comments-textarea--singleLine .comments-textarea__input {
  padding: 10px;
  border: 2px inset;
}

.comments-textarea--multiLine .comments-textarea__control {
  width: 100%;
  border: 1px solid var(--fp-secondary-outline);
  /* padding: 10px 12px; */
  position: relative;
  font-size: 13px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  background-color: transparent;
  color: var(--fp-secondary-font-dark);
  /* height: 32px; */
}

.comments-textarea--multiLine .comments-textarea__highlighter {
  padding: 6.25px;
  padding-right: 30px;
}

.comments-textarea--multiLine .comments-textarea__input {
  padding: 6px 30px 10px 10px;
  min-height: 63px;
  outline: 0;
  border: 0;
}
.sr-comments-textarea .sr-comments-textarea__input {
  padding: 6px 30px 10px 30px;
  min-height: 63px;
  outline: 0;
  border: 0;
}

.sr-comments-textarea__highlighter{
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  min-width: 15rem;
  max-height: 18rem;
  overflow: auto;
  padding: 5px 15px;
  border-radius: 8px;
}

.comments-textarea__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  min-width: 15rem;
  max-height: 18rem;
  overflow: auto;
}

.comments-textarea__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.comments-textarea__suggestions__item--focused {
  color: #0077d2;
}

.comments-textarea__mention {
  color: #0077d2;
  position: relative;
}

/* .comments-textarea__mention::before {
  content: "@";
} */

/*login error css*/
.login-error-box {
  padding: 10px;
  background: rgba(201, 67, 67, 0.625);
  color: #ffffff;
  border-radius: 5px;
}

/*Forget Pass css*/
.forget-pass-info {
  padding: 5px;
  color: #c0c0c0;
  font-size: 17px;
}

/*Register Form css*/
.register-form-tick {
  position: absolute;
  right: 7%;
  top: 40%;
}

/*mentions-social-filter*/
.social-filter-connect {
  font-size: 12px;
  padding: 1px 6px;
  border: 1px solid darkgrey;
  border-radius: 2px;
  transition: all 0.4s ease-in-out;
}

.social-filter-connect:hover {
  border-color: #ef3d86;
  color: #ef3d86;
}

.pay-now-txt {
  color: #3986fa;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.pay-now-txt:hover {
  color: #3986fa;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

/*Slide Animations*/
.slide-enter {
  transform: translateY(-100%);
  transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-active {
  transform: translateY(0%);
}

.slide-leave {
  transform: translateY(0%);
  transition: 0.3s ease-in-out;
}

.slide-leave-active {
  transform: translateY(-100%);
}

/*Date Input*/
.date-input > .flatpickr-calendar {
  position: fixed;
}

.date-input > .flatpickr-calendar .flatpickr-monthDropdown-months {
  background: #42a5f5;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: inherit;
  font-family: "Outfit", sans-serif !important;
  font-weight: inherit;
  height: auto;
  width: auto;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  color: inherit;
  display: inline-block;
}

.date-input > .flatpickr-calendar .numInputWrapper {
  margin-left: 5px;
}

.date-input > .flatpickr-calendar .numInputWrapper .numInput {
  background: transparent;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: "Outfit", sans-serif !important;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  width: 100%;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  appearance: textField;
}

.date-input > .flatpickr-calendar .numInputWrapper .numInput:focus {
  color: inherit;
  background: inherit;
  width: 100%;
}

.contacts-filter-search .fp-input{
  height: 32px !important;
  width: 250px;
  border-radius:8px;
}
.contacts-filter-search .fp-input input{
  padding: 7px 12px 7px 40px;
}

.common-search .fp-input{
  height: 32px !important;
  width: 300px;
  border-radius:8px;
}
.common-search .fp-input input{
  padding: 7px 12px 7px 40px;
}

.deleteIcon:hover {
  cursor: pointer;
}

.msg-user-list {
  /* height: 77vh; */
  width: 100%;
  border-radius: 0.2rem;
  gap: 1rem;
  align-items: center;
  transition: 0.5s ease-in-out;
}

.contact {
  /* height: 80vh;
	overflow-y: auto;
	width: 100%; */
}

.brand {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 8px 0px;
  /*background-color: #ccc;*/
  margin-bottom: 5px !important;
  cursor: pointer;
}

.brand:hover {
  background-color: #ecf0f4;
  border-right: 3px solid #479ddb;
}

.brand > .contactrow > .contract-list {
  align-items: center;
}

.brand > .contactrow > .row > .avtaarcol > .logoImageDiv {
  /*padding: 5px;*/
  /*background: #fff;*/
  border-radius: 32px !important;
  justify-content: center !important;
  display: flex;
  height: 2.5vw;
  width: 2.5vw;
  /*box-shadow: 0px 2px 20px 5px rgb(195 195 195 / 70%);*/
  -moz-box-shadow: 10px 10px 5px 0px rgba(195, 195, 195, 0.55);
}

.brand > .contactrow > .row > .cus_name > h4,
.topusernamecol h5 {
  font-size: 18px;
  font-weight: 500;
  color: var(--fp-secondary-font-dark);
  margin-bottom: 1px;
}

.brand > .contactrow > .row > .cus_name > p,
.topusernamecol p,
.onlineshowcol p {
  color: #7a8193;
}

.onlineshowcol .count-messagee {
  color: #000;
  font-size: 0.8vw;
  background-color: #adc6df;
  height: 2vw;
  width: 2vw;
  border-radius: 50%;
  text-align: center;
  /* vertical-align: initial; */
  padding: 0.5vw;
}

.brand > .contactrow > .lastmessageshow,
.brand > .contactrow > .contactdescription {
  font-size: 0.7vw;
  font-weight: 400;
}

.pd-2 {
  padding: 5px 5px;
}

.brand > .contactrow > .row > .avtaarcol > .logoImageDiv > img {
  max-width: 100%;
}

.unrespondedList .logoImageDiv > img {
  max-width: 60%;
}

.unrespondedList .logoImageDiv {
  padding: 5px;
  background: #fff;
  border-radius: 32px !important;
  justify-content: center !important;
  display: flex;
  height: 2.5vw;
  width: 2.5vw;
  box-shadow: 0px 2px 20px 5px rgb(195 195 195 / 70%);
  -moz-box-shadow: 10px 10px 5px 0px rgba(195, 195, 195, 0.55);
}

/*.unrespondedMessage {
	height: 30vh;
    overflow-y: scroll;
    width: 100%;
    padding: 0 0 45px 45px;
}*/
.unrespondedList:hover {
  cursor: pointer;
  background-color: #ecf0f4;
  border-right: 3px solid #479ddb;
}

.unrespondedList .cus_name h4 {
  font-size: 1vw;
  font-weight: 600;
  color: #4b4242;
  margin-bottom: 5px;
}

/*.unrespondedList:hover {
    background-color: #ecf0f4;
    border-right: 3px solid #479ddb;
}*/

.unrespondedList .cus_name p {
  color: #adc6df;
}

.brand > h3 {
  color: #000;
  text-transform: capitalize;
}

.onlineshow {
  height: 10px;
  width: 10px;
  background: green;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.onlineshowcol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avtaarcol {
  padding: 2px !important;
  justify-content: center;
  align-items: center;
  display: inherit;
  padding-left: 5px !important;
}

.marginTop10 {
  margin-top: 10px;
}

.userChatRow {
  display: flex;
  align-items: center;
}

.chatBox {
  height: 48vh;
  padding-right: 10px !important;
  overflow-y: auto;
  justify-content: center;
}

.chatInputBox {
  /*min-height: 18vh;*/
  border: 1px solid #edeff3;
  /* margin-top: 5px!important; */
}

.chatInputBox .chatForm {
  width: 100%;
}

.chatInputBox .chatForm label {
  margin-bottom: unset !important;
}

.chatBox .date-wise-chat .chat-date {
  font-size: 12px;
  color: var(--fp-secondary-font-dark);
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.chatBox .date-wise-chat .chat-date span {
  background: #fff;
  padding: 0 10px;
}

.left_side_bar {
  /* padding-right: 0 !important; */
}

.imagetop {
  display: flex;
  align-items: center;
  gap: 5px;
}

.imagetop > img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.upperchattabs {
  height: 7.5vh !important;
  padding-left: 0px !important;
}

.upperchattabs .nav-tabs .nav-item {
  margin-right: 20px;
}

.upperchattabs .nav-tabs {
  padding: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.upperchattabs .nav-tabs .nav-link {
  color: #000 !important;
  font-size: 0.8vw !important;
  font-weight: unset !important;
}

.msgsendmiddlesection {
  height: 10vh;
  padding-left: 0px !important;
}

.msgsendendection {
  height: 6vh;
  padding-left: 0px !important;
  background-color: #f8fbfe;
  border: 1px solid #f0f1f4;
}

.msgtextarea {
  height: 100%;
  width: 100%;
  border: 1px solid #edeff3 !important;
  /*border-top: 0!important;*/
  margin-top: 10px;
}

.msgtextarea:focus-visible {
  outline: none !important;
}

.buttonChatColumn {
  display: flex;
  justify-content: flex-end;
}

.sendbtn {
  width: 100%;
  background-color: #064f5d;
  color: #fff;
  border-radius: 8px;
  padding: 8px 0;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid #064f5d;
}

.sentMsgBox12 {
  /*height: 65px;*/
}

.sentMsgBox {
  border: 1px solid #edeff1;
  border-radius: 10px;
  /*height: 65px;*/
  padding: 10px;
  font-weight: 500;
  background-color: #ecf0f4;
}

.sentMsgBox .messages-file-download,
.receiveMsgBox .de-messages-file-download {
  width: 8%;
  background-color: #b8b6b682;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  top: -10px;
  right: 0;
  display: none;
}

.sentMsgBox .messages-file-download i,
.receiveMsgBox .de-messages-file-download i {
  font-size: 1vw !important;
}

/*.sentMsgBox:hover .messages-file-download, .receiveMsgBox:hover .de-messages-file-download{
	display: block;
}*/

.receiveMsgBox12 {
  /*height: 65px;*/
}

.receiveMsgBox {
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  border-radius: 10px;
  /*height: 65px;*/
  padding: 10px;
  font-weight: 500;
}

.receiveMsgDateBox,
.sentMsgDateBox {
  font-size: 11px;
  font-weight: 400;
  color: #7a8193;
  margin-top: 5px;
  display: block;
  text-align: start;
}

.internalnotemessage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px !important;
  border: 1px solid #ccc !important;
  border-radius: 10px !important;
}

.messenger-setup .nav-tabs .nav-link {
  color: #000 !important;
  font-size: 1vw;
  font-weight: 600;
}

.messenger-setup .nav-tabs .nav-link.active {
  border-bottom: 4px solid #1b5d6a !important;
}

.messenger-setup .nav-tabs {
  /*justify-content: space-around;*/
  padding: 0 50px;
  border-bottom: 2px solid #ececf1;
}

.messenger-setup .nav-tabs .first-item {
  margin-right: 50px;
}

.business-setup .nav-tabs .nav-link:hover {
  /* border-color: transparent; */
  /* border-bottom: 4px solid #1b5d6a !important; */
}

.messenger-loader .loaderrr {
  justify-content: center;
  /*margin: 25rem 0;*/
}

.chat-type-content {
  padding: 0 65px !important;
}

.chat-input-icons {
  display: flex;
  align-items: center;
}

.chat-input-icons input {
  display: none;
}

.chat-input-icons p {
  font-size: 1vw;
  font-weight: 600;
  cursor: pointer;
}

.chat-input-icons i {
  cursor: pointer;
}

.message-header {
  width: 100%;
  padding: 0 0 0 50px;
}

.message-header .header-plus-button .plus-btn {
  text-align: end;
}

.message-header .header-plus-button .plus-btn .btnn {
  background-color: #064e5c;
  border-color: #064e5c;
}

.message-header .header-plus-button .plus-btn .btnn:active {
  background-color: #064e5c;
  border-color: #064e5c;
}

.message-header .header-plus-button .plus-btn .btnn i {
  font-size: 0.8vw;
}

.message-header .header-dropdownn .dropdown-toggle {
  width: 100%;
  text-align: end;
  background-color: #fff !important;
  color: #000 !important;
  border: unset;
  font-size: 1vw;
}

.contact .header-dropdownn .collapseButton:focus {
  box-shadow: unset !important;
}

.contact .header-dropdownn .collapseButton:active {
  box-shadow: unset !important;
}

.contact.header-dropdownn .dropdown-toggle:active {
  width: 100%;
  text-align: end;
  background-color: #fff !important;
  color: #000 !important;
  border: unset;
  box-shadow: unset !important;
}

.contact .header-dropdownn .dropdown-toggle:focus {
  box-shadow: unset !important;
}

.message-header .header-dropdownn .dropdown-menu {
  width: 100%;
}

.topusern-button button {
  background-color: #fff !important;
  color: #8996aa !important;
  border: 1px solid #e0e1e6 !important;
}

.topusern-button-menu button {
  background-color: #fff !important;
  color: #8996aa !important;
  border: unset;
  box-shadow: unset !important;
}

.topusern-button-menu .add-patient-btn {
  border: 1px solid #e0e1e6 !important;
  color: #000 !important;
}

.topusern-button-menu .user-info {
  font-size: 0.8vw;
  color: #000 !important;
  font-weight: 500;
}

.topusern-button-menu .add-mail-btn,
.topusern-button-menu .add-phone-btn {
  color: #7aafd2 !important;
}

.messenger-assign-modal-title h5 {
  font-weight: 600;
  font-size: 1vw;
}

.filter-content .hide-filter-setup,
.filter-content .reset-filter-setup {
  color: #8a8e94;
  font-size: 0.8vw;
  font-weight: 600;
  cursor: pointer;
}

/* survey section */
.survey-section-div {
  border: 1px solid var(--fp-secondary-outline);
  padding: 13px;
  border-radius: 8px;
}

.survey-section-page {
  /* color: var(--secondary-blue-gray);
	font-weight: 500; */
  color: #7a8193;
  font-weight: 400;
}

.survey-section-div .survey-section-dropdown {
  text-align: right;
}

.survey-section-div .survey-section-dropdown .dropdown-toggle,
.add-question-div .dropdown-toggle {
  background-color: #fff;
  color: #000;
}

.survey-section-div .survey-section-dropdown .dropdown-toggle {
  background-color: #fff;
  color: #000;
  border: 3px solid #ff8939 !important;
}

.survey-section-div .survey-section-dropdown .dropdown-toggle:focus {
  background-color: #fff;
  color: #000;
  border: 3px solid #ff8939 !important;
  box-shadow: unset !important;
}

.survey-section-div .survey-section-dropdown .show .dropdown-toggle {
  background-color: #fff !important;
  color: #000 !important;
  border: 3px solid #ff8939 !important;
  box-shadow: unset !important;
}

.add-question-div .dropdown-toggle {
  background-color: #1976d2 !important;
  color: #fff !important;
  border: 3px solid #ff8939;
}

.survey-section-div .survey-section-dropdown .dropdown-toggle:hover {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: unset !important;
  border: 3px solid #ff8939 !important;
}

.add-question-div .dropdown-toggle:hover {
  background-color: #1976d2 !important;
  color: #fff !important;
  border: 3px solid #ff8939 !important;
}

.add-question-div .dropdown-menu {
  width: 30rem !important;
  min-width: unset !important;
  left: -125px !important;
  /*text-align: center!important;*/
  padding: 15px 30px !important;
}

.add-question-div .dropdown-toggle:focus {
  background-color: #1976d2 !important;
  color: #fff !important;
  border: 3px solid #ff8939 !important;
  box-shadow: unset !important;
}

.add-question-div {
  text-align: center;
}

.page-title-section,
.choices-question-div {
  background-color: var(--fp-surface-color);
  padding: 15px;
  border-radius: 6px;
}

.page-title-section .page-title-input label {
  font-size: 13px;
  font-weight: 500;
  color: var(--fp-secondary-font-dark);
  margin-bottom: 7px;
}

.page-title-section-button {
  text-align: end;
}

.survey-section-div .done-btn {
  background-color: #0077d2 !important;
  color: #fff !important;
}

.add-more-choices-option {
  background-color: #fff !important;
  color: #0077d2 !important;
  border: 1px solid #0077d2 !important;
}

.surveyPhoneNumber input {
  border: unset !important;
  outline: unset !important;
}

/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

div input{
    border: none;
    border-bottom: 1px solid black;
}

div.Image_url{
    background-image:url('https://unsplash.com/photos/LN9WbxmgSY0');
} */

#speedometer .wrapper {
  width: 300px;
  height: 150px;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  overflow: hidden;
  position: relative;
}

#speedometer .wrapper .indicator-wrapper {
  position: absolute;
  left: 23%;
  top: 43%;
  width: 54%;
  height: 80%;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  background-color: #ffffff;
  z-index: 400;
}

#speedometer .wrapper .bar {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-origin: bottom center;
}

#speedometer .wrapper .bar-1 {
  top: -20px;
  left: -81px;
  z-index: 200;
  transform: rotate(-160deg);
  background-color: rgb(255 0 0);
  border-bottom: 1px dotted white;
}

#speedometer .wrapper .bar-1 span {
  top: 120px;
  left: 178px;
  font-size: 15px;
  position: absolute;
  transform: rotate(161deg);
  color: white;
}

#speedometer .wrapper .bar-2 {
  top: -4px;
  right: 6px;
  z-index: 150;
  transform: rotate(-147deg);
  background-color: rgb(242 48 0);
  border-bottom: 1px dotted white;
}

#speedometer .wrapper .bar-2 span {
  position: absolute;
  top: 119px;
  left: 256px;
  font-size: 15px;
  transform: rotate(-214deg);
  color: white;
}

#speedometer .wrapper .bar-3 {
  top: 21px;
  left: -173px;
  z-index: 100;
  transform: rotate(49deg);
  background-color: rgb(230 92 0);
  border-top: 1px dotted white;
}

#speedometer .wrapper .bar-3 span {
  position: absolute;
  top: 8px;
  left: 126px;
  transform: rotate(-48deg);
  font-size: 15px;
  color: white;
}

#speedometer .wrapper .bar-4 {
  top: -3px;
  left: -155px;
  z-index: 97;
  transform: rotate(68deg);
  background-color: rgb(217 130 0);
  border-top: 1px dotted white;
}

#speedometer .wrapper .bar-4 span {
  position: absolute;
  top: 10px;
  left: 90px;
  transform: rotate(-69deg);
  font-size: 15px;
  color: white;
}

#speedometer .wrapper .bar-5 {
  top: -58px;
  left: -145px;
  z-index: 90;
  transform: rotate(88deg);
  background-color: rgb(204 163 0);
  border-top: 1px dotted white;
}

#speedometer .wrapper .bar-5 span {
  position: absolute;
  top: 9px;
  left: 93px;
  transform: rotate(-89deg);
  font-size: 15px;
  color: white;
}

#speedometer .wrapper .bar-6 {
  top: -58px;
  left: -134px;
  z-index: 80;
  transform: rotate(108deg);
  background-color: rgb(192 192 0);
  border-top: 1px dotted white;
}

#speedometer .wrapper .bar-6 span {
  position: absolute;
  top: 6px;
  left: 43px;
  transform: rotate(-106deg);
  font-size: 15px;
  color: white;
}

#speedometer .wrapper .bar-7 {
  top: -107px;
  left: -102px;
  z-index: 70;
  transform: rotate(128deg);
  background-color: rgb(143 179 0);
  border-top: 1px dotted white;
}

#speedometer .wrapper .bar-7 span {
  position: absolute;
  top: 6px;
  left: 52px;
  transform: rotate(232deg);
  font-size: 15px;
  color: white;
}

#speedometer .wrapper .bar-8 {
  top: -118px;
  left: -71px;
  z-index: 60;
  transform: rotate(141deg);
  background-color: rgb(100 166 0);
  border-top: 1px dotted white;
}

#speedometer .wrapper .bar-8 span {
  position: absolute;
  top: 5px;
  left: 49px;
  transform: rotate(217deg);
  font-size: 15px;
  color: white;
}

#speedometer .wrapper .bar-9 {
  top: -54px;
  left: 17px;
  z-index: 50;
  transform: rotate(148deg);
  background-color: rgb(61 153 0);
}

#speedometer .wrapper .bar-9 span {
  position: absolute;
  top: 84px;
  left: 73px;
  transform: rotate(212deg);
  font-size: 15px;
  color: white;
}

#speedometer .wrapper .bar-10 {
  top: 4px;
  left: 13px;
  z-index: 50;
  transform: rotate(161deg);
  background-color: rgb(28 141 0);
  border-bottom: 1px dotted white;
}

#speedometer .wrapper .bar-10 span {
  position: absolute;
  top: 122px;
  left: 37px;
  transform: rotate(198deg);
  font-size: 15px;
  color: white;
}

#speedometer .needle {
  width: 80px;
  height: 7px;
  background: #15222e;
  border-bottom-left-radius: 100% !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 100% !important;
  border-top-right-radius: 5px !important;
  position: absolute;
  bottom: 0px;
  left: 83px;
  transform-origin: 100% 4px;
  transform: rotate(0deg);
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
  z-index: 1005;
}

.semiCircleProgressBar {
  position: absolute !important;
  top: 70px !important;
  left: 110px;
  width: 162px !important;
  z-index: 1001 !important;
  height: 85px !important;
}

.semiCircleProgressBar .semicircle-container svg circle:nth-child(2) {
  transition: stroke-dashoffset 0s linear 0s, stroke-dasharray 0s linear 0s,
    stroke 0s linear 0s !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: unset !important;
}

.modal-content {
  width: 580px !important;
}

.memberForm .modal-content {
  width: 800px !important;
}

.memberLeftBlock {
  display: block !important;
}

.memberFormColumnField {
  width: 48% !important;
}

.memberFormField {
  width: 100% !important;
  margin-left: 30px !important;
}

.memberFormSubmitBtn {
  width: 95% !important;
}

.dobDatePicker
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  border: "unset !important";
  outline: "none !important";
  border-bottom: "1.1px solid black";
  border-radius: "0px !important";
}

.doaDatePicker
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  border: "unset !important";
  outline: "none !important";
  border-bottom: "1.1px solid black";
  border-radius: "0px !important";
}

.social_post_calendar .react-calendar__month-view__days {
  min-height: 750px !important;
}

.social_post_calendar .react-calendar__month-view__days button {
  border-color: #374760 !important;
  border-collapse: collapse !important;
}

.social_post_calendar .react-calendar__navigation button {
  border: unset !important;
  font-size: 25px !important;
  color: #007bff !important;
}

.react-calendar__month-view__weekdays {
  background-color: #374760 !important;
  color: white !important;
}

.post_social_modal_content .nav-tabs .nav-item {
  width: 33% !important;
  text-align: center !important;
  font-size: 20px !important;
}

.post_social_modal_content {
  padding: 0 !important;
}

.post_new_form_segment {
  padding: 30px !important;
  border: unset !important;
}

.location_list_scroll {
  height: 400px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 10px;
}

.schedule_time_input {
  width: 100%;
  margin-top: 10px;
  border: 1px solid gray;
  padding: 0 6px;
}

.edit_post_social_modal_content {
  padding: 10px 25px !important;
}

#edit_post_title {
  width: 100% !important;
  border: 1px solid gray;
  padding: 10px !important;
}

#edit_post_desc {
  width: 100% !important;
  padding: 10px !important;
}

#edit_event_start_date {
  padding: 10px !important;
  border: 1px solid gray;
}

#edit_event_end_date {
  padding: 10px !important;
  border: 1px solid gray;
}

#edit_schedule_date {
  padding: 16px !important;
  border: 1px solid gray;
}

#edit_schedule_time {
  padding: 10px !important;
  border: 1px solid gray;
}

#edit_add_button {
  border: 1px solid gray;
}

.edit_location_list_scroll {
  height: 200px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 10px;
}

.social_table_title {
  border: unset !important;
  background-color: white !important;
  color: #007bff !important;
}

.social_table_title:focus {
  box-shadow: unset !important;
  outline: unset !important;
}

.surveyTable table,
.surveyTable th,
.surveyTable td {
  border: 1px solid #2224261a;
  border-collapse: collapse;
}

.select-branch-dropdown .Select-value {
  max-width: 90% !important;
}

.branch_cell {
  max-width: 200px !important;
  white-space: break-spaces !important;
}

.page_cell {
  max-width: 200px !important;
  white-space: break-spaces !important;
}

.question_cell {
  max-width: 200px !important;
  white-space: break-spaces !important;
}

.option_cell {
  max-width: 200px !important;
  white-space: break-spaces !important;
}

.ui.orange.label {
  background-color: red !important;
}

.competitor_table {
  text-align: center !important;
}

/* .openTicketStatusTable_count{
	font-size: 30px !important;
	margin-top: 15px !important;
} */
.openTicketStatusTable_new_divider {
  background-color: blue !important;
  width: 20px !important;
}

.openTicketStatusTable_assigned_divider {
  background-color: green !important;
  width: 20px !important;
}

.openTicketStatusTable_reopened_divider {
  background-color: red !important;
  width: 20px !important;
}

.openTicketStatusTable_resolved_divider {
  background-color: orange !important;
  width: 20px !important;
}

.competitor_filter_search div {
  width: 100% !important;
}

.compt-font {
  font-size: 13px !important;
}

.openTicketStatusTable_new_count {
  font-size: 30px !important;
  margin-top: 15px !important;
  color: blue !important;
}

.openTicketStatusTable_assigned_count {
  font-size: 30px !important;
  margin-top: 15px !important;
  color: green !important;
}

.openTicketStatusTable_reopened_count {
  font-size: 30px !important;
  margin-top: 15px !important;
  color: red !important;
}

.openTicketStatusTable_resolved_count {
  font-size: 30px !important;
  margin-top: 15px !important;
  color: orange !important;
}

.competitor_category_report .ui.tiny.progress {
  font-size: 5px !important;
  height: 5px !important;
}

.table_column {
  white-space: break-spaces !important;
  max-width: 200px !important;
}

.table_row {
  max-width: 150px !important;
}

.first-row {
  background-color: #d4f1f4 !important;
}

.smallWidthTableColumn {
  max-width: 100px !important;
  white-space: break-spaces !important;
}

.TatStatics {
  font-size: 8px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 5px !important;
  color: gray !important;
}

.TatStatics .col-sm-4 {
  padding-right: 2px !important;
  padding-left: 0px !important;
}

.TatStatics p {
  text-align: center !important;
}

.dashboardReviewsStatus_responded {
  font-size: 18px !important;
  color: blue !important;
}

.dashboardReviewsStatus_unresponded {
  font-size: 22px !important;
  color: #1b1c1d !important;
  font-weight: 500 !important;
}

.firstPageImageLabel {
  border: 1px dashed rgba(34, 36, 38, 0.15);
  padding: 7px 15px;
  border-radius: 1rem;
  cursor: pointer;
}

.generate-report {
  display: flex;
  align-items: center !important;
  background: #f76087 !important;
  border-radius: 1rem !important;
  border: none !important;
  color: #fff !important;
  border: none !important;
}

.generate-report.text {
  color: #000 !important;
}

.generate-report i {
  background: #c77c8f !important;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 12px 12px !important;
  margin: -7.2px !important;
  right: 7px !important;
  top: 7px !important;
}

.generate-report.ui.dropdown:not(.button) > .default.text {
  color: #fff !important;
}

@media screen and (max-width: 1200px) {
  .generate-report {
    margin-top: 10px;
    margin-left: 0px !important;
  }

  .generate-report i {
    padding: 11px 12px !important;
  }
}

@media screen and (max-width: 820px) {
  .upload-first-img {
    margin-top: 6px;
    margin-right: 10px;
  }
}

.ai-report-card {
  border-radius: 0.28571429rem !important;
  box-shadow: none !important;
}

.ai-report-card-body h5 {
  font-weight: 600 !important;
}

.ai-report-card-body p {
  opacity: 0.6 !important;
}

.switchToggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  max-width: 70px;
  height: 30px;
  background: #d1d1d1;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switchToggle label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switchToggle input:checked + label,
.switchToggle input:checked + input + label {
  background: #3e98d3;
}

.switchToggle input + label:before,
.switchToggle input + input + label:before {
  content: "OFF";
  position: absolute;
  top: 5px;
  left: 35px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked + label:before,
.switchToggle input:checked + input + label:before {
  content: "ON";
  position: absolute;
  top: 5px;
  left: 10px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked + label:after,
.switchToggle input:checked + input + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchToggle label:active:after {
  width: 60px;
}

.toggle-switchArea {
  margin: 10px 0 10px 0;
}

.channel-icon-images {
  width: 70px !important;
  height: 80px !important;
}

.dv-star-rating-star {
  margin-bottom: 0 !important;
}

/* Material Table ui */
.fp-table .MuiTableHead-root {
  background-color: var(--fp-surface-color, #f6f6f8) !important;
}

.fp-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
  color: var(--secondary-font-blue, #1a2544) !important;
  border: unset;
  font-size: 14px;
}

.fp-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  color: var(--fp-secondary-font-dark, #1b1c1d) !important;
  border: none !important;
  font-size: 13px;
}

.fp-table .MuiTableBody-root .MuiTableRow-root {
  border-bottom: 1px solid #e8e8e8 !important;
}

.fp-table.fp-table-center
  .MuiTableHead-root
  .MuiTableRow-root
  .MuiTableCell-root,
.fp-table.fp-table-center
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  text-align: center;
}

.fp-table-caption {
  margin-left: auto !important;
  color: var(--violet-red-600, #e12169);
  text-align: end;
  float: right;
}

/* Material Table ui end */

/* semantic-ui-react Table ui  */
.fp-table.ui {
  margin: 0 !important;
  border: 0 !important;
}

.fp-table.ui thead tr th {
  margin: 0 !important;
  padding: 6px !important;
  color: #7a8193 !important;
  background-color: #ffffff !important;
  border-color: #e8e8e8 !important;
  font-family: "Outfit", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.fp-table.ui tbody tr td {
  margin: 0 !important;
  padding: 8px 6px;
  color: var(--fp-secondary-font-dark, #1b1c1d) !important;
  font-family: "Outfit", sans-serif !important;
  font-size: 14px !important;
  vertical-align: middle !important;
}

.ui.table thead tr:first-child > th:last-child,
.ui.table thead tr:first-child > th:first-child {
  border-radius: 0 !important;
}

/* end semantic-ui-react Table ui  */

.h-40 {
  height: 40% !important;
}

.br-12 {
  border-radius: 12px !important;
}

.custom-checkbox {
  height: 100px;
  width: 100px;
}

.circular-button {
  border-radius: 50% !important;
  padding: 6px 6px !important;
  border: 1px solid var(--fp-secondary-outline) !important;
  color: "#7A8193";
  width: 30px ;
  height: 30px;
}

.circular-button img {
  width: 20px;
}

.active-button .circular-button,
.active-button.circular-button {
  background-color: var(--fp-primary-color);
  color: #ffffff;
  border: 1px solid var(--fp-secondary-outline) !important;
}

.fp-active-btn {
  background-color: var(--fp-primary-color) !important;
  color: #ffffff !important;
  border: unset !important;
}

.user-easy-access-wrapper.MuiMenuItem-root {
  /* color: #ffffff; */
  /* background-color: var(--fp-Blue-Chill-50); */
  font-weight: 500;
}

.user-easy-access-wrapper.MuiMenuItem-root:hover {
  /* background-color: var(--fp-Blue-Chill-50); */
  background-color: #ffffff !important;
}

.user-easy-access-wrapper.MuiMenuItem-root p:first-child {
  /* color: var(--fp-Primary-TILLA-KARI); */
  color: #00cbcb;
  font-size: 16px;
  font-weight: 600;
}

.user-easy-access-wrapper.MuiMenuItem-root p:nth-child(2) {
  color: #1b1c1d;
  font-size: 14px;
  font-weight: 400;
}

.navBar-user {
  color: var(--fp-secondary-font-dark);
  position: relative;
  font-size: 16px;
  font-weight: 400;
}

.provider-label {
  color: #1a2544;
  border: 1px solid #f13a88;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  background-color: #fdf2f7;
  border-radius: 80px !important;
  padding: 7px 11px;
}

.modal-dialog {
  margin-top: 80px !important;
}

/* LOGIg-page */
.hero-image-wrapper {
  background-color: #f3f3f5;
  padding: 70px 75px !important;
  border-radius: 70px 0 0 70px;
  box-shadow: 0px 38.4px 76.8px -14.4px rgba(16, 24, 40, 0.14);
}

.hero-image-wrapper::before {
  content: "";
  background: url("../images/line-pattern.png");
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: 1;
  background-position-y: bottom;
}

.hero-image-wrapper::after {
  content: "";
  background: url("../images/line-pattern.png");
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: 1;
  background-position-y: bottom;
  rotate: 180deg;
}

.hero-image {
  border-radius: 24px;
  object-fit: cover;
  object-position: top right;
  width: 1500px;
  position: relative;
  z-index: 2;
}

.ticket-tag-add input {
  border-radius: 8px 0 0 8px;
}

.ticket-tag-inputs-wrapper {
  position: sticky;
  background: white;
  top: 0;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.coupon-list-table {
  height: 91vh;
  overflow-x: hidden;
}

.offerHeaderSection {
  text-align: center !important;
}

.offerDiscountText,
.offerDiscountCode {
  margin-top: 3px;
  margin-bottom: 3px;
  color: #00cccc !important;
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

.couponCodeSummaryDivider_total {
  background-color: blue !important;
  width: 20px !important;
  margin: 15px auto !important;
}

.couponCodeSummaryDivider_issued {
  background-color: green !important;
  width: 20px !important;
  margin: 15px auto !important;
}

.couponCodeSummaryDivider_redeemed {
  background-color: red !important;
  width: 20px !important;
  margin: 15px auto !important;
}

.couponCodeSummaryDivider_remaining {
  background-color: orange !important;
  width: 20px !important;
  margin: 15px auto !important;
}

.couponCodeSummarycount_total {
  font-size: 30px !important;
  margin-top: 15px !important;
  color: blue !important;
}

.couponCodeSummarycount_issued {
  font-size: 30px !important;
  margin-top: 15px !important;
  color: green !important;
}

.couponCodeSummarycount_redeemed {
  font-size: 30px !important;
  margin-top: 15px !important;
  color: red !important;
}

.couponCodeSummarycount_remaining {
  font-size: 30px !important;
  margin-top: 15px !important;
  color: orange !important;
}

/* .dashedBorderThankYouPage{
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50' ry='50' stroke='%23333' stroke-width='4' stroke-dasharray='10%2c 20' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
	border-radius: 50px;
} */

.sideNavSections .sidenav-items .item {
  font-size: 12px !important;
}

.custom-modal-style {
  width: 710px !important;
}

.margin-lr-main {
  margin: 0 -15px !important;
}

@media (min-width: 360px) and (max-width: 768px) {
  .agency-card-details-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .agency-card-details-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .agency-card-details-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .card-wrapper-sm {
    width: 78% !important;
  }

  .agency-filter-bar > {
  }

  .agency-page-header > h2 {
    font-size: 24px;
  }

  .fp-btn-add-bussiness > span {
    font-size: 9px;
    padding: 8px;
    min-width: 80px !important;
  }

  .fp-btn-add-bussiness > span > span > svg {
    height: 16px;
    margin-right: -8px;
  }

  /* .div-agency-in-header>div>div>p{
font-size: 12px;
} */
  .summary-label {
    font-size: 11px !important;
    /* width: min-content; */
  }

  .summary-value {
    font-size: 12px !important;
    width: min-content;
  }

  .divider-hr {
  }

  .div-agency-in-header > div {
    /* height: 78px; */
  }

  .circular-button {
    border-radius: 50% !important;
    padding: 3px 3px !important;
    border: 1px solid var(--fp-secondary-outline) !important;
  }

  .active-button .circular-button,
  .active-button.circular-button {
    background-color: var(--fp-primary-color);
    color: #ffffff;
    border: unset !important;
  }

  .header-logo-famepilot {
    height: 35px;
  }

  .profile-user-display > div {
    height: 30px;
    width: 30px;
  }

  .margin-lr-main {
    margin: 0 2px !important;
  }

  .margin-lr-main > div {
    display: block;
  }

  .margin-lr-main .makeStyles-content-10 {
    padding: 0 5px !important;
  }

  .fp-btn-add-bussiness {
    min-width: 80px !important;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .agency-card-details-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .card-wrapper-sm {
    width: 78% !important;
  }
}

@media (min-width: 768px) and (max-width: 1025px) {
  .agency-card-details-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .card-wrapper-sm {
    width: 78% !important;
  }
}

@media (max-width: 319px) {
  .agency-card-details-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .card-wrapper-sm {
    width: 78% !important;
  }

  .agency-filter-bar > {
  }

  .agency-page-header > h2 {
    font-size: 22px;
  }

  .fp-btn-add-bussiness {
    min-width: 80px !important;
  }

  .fp-btn-add-bussiness > span {
    font-size: 10px;
    padding: 8px;
    text-wrap: balance !important;
  }

  /* .div-agency-in-header>div>div>p{
font-size: 12px;
} */
  .summary-label {
    font-size: 10px !important;
    /* width: min-content; */
  }

  .summary-value {
    font-size: 11px !important;
    width: min-content;
  }

  .divider-hr {
  }

  .div-agency-in-header > div {
    /* height: 78px; */
  }

  .circular-button {
    border-radius: 50% !important;
    padding: 3px 3px !important;
    border: 1px solid var(--fp-secondary-outline) !important;
  }

  .active-button .circular-button,
  .active-button.circular-button {
    background-color: var(--fp-primary-color);
    color: #ffffff;
    border: unset !important;
  }

  .header-logo-famepilot {
    height: 30px;
  }

  .profile-user-display > div {
    height: 30px;
    width: 30px;
  }

  .margin-lr-main {
    margin: 0px !important;
  }

  .btn-text-wrap {
    text-wrap: balance;
  }

  .margin-lr-main .makeStyles-content-10 {
    padding: 0 5px !important;
  }

  .margin-lr-main > div {
    display: block;
  }
}

.fade:not(.show) {
  opacity: 1 !important;
}

.close_1tcvdj4 {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: fixed !important;
  top: 10px !important;
  vertical-align: bottom;
  z-index: 1;
  right: 20px !important;
  height: 40px;
  margin-right: -10px;
  padding: 5px !important;
  width: 40px;
  fill: white;
}

.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: 0 !important;
}

@media only screen and (max-width: 600px) {
  .font-gray {
    font-size: 10px !important;
  }

  .fp-size-18 {
    font-size: 11px !important;
  }

  /* .Component-label-18 {
		font-size: 10px !important;
	} */

  .headerText {
    font-size: 23px !important;
  }

  /* .Component-label-16 {
		text-wrap: wrap;
	} */
  .applyReset {
    width: 100%;
  }

  .ui.dropdown > .text {
    white-space: wrap;
    font-size: 12px;
  }

  .applyReset {
    width: 100%;
  }

  .searchFieldText {
    margin-left: 16px;
  }

  .d-flex.gap-20.align-items-center.fp-flex-grow-1.col-12.col-lg-8.searchFieldText {
    padding-right: 3px;
    padding-left: 0px;
  }

  .ui.compact.labeled.icon.buttons .button,
  .ui.compact.labeled.icon.button {
    min-width: 100% !important;
    padding-left: 3.071429em !important;
  }
}

@media only screen and (min-width: 600px) {
  .font-gray {
    font-size: 14px !important;
  }

  .fp-size-18 {
    font-size: 18px !important;
  }

  .mx-20 {
    margin: 0 20px !important;
  }

  /* .Component-label-18 {
		font-size: 12px !important;
	} */
  /* .Component-root-17 {
		height: 50px !important;
	} */
  /* .Component-label-16 {
		text-wrap: nowrap;
	} */
}

@media (min-width: 768px) and (max-width: 992px) {
  .ui.compact.labeled.icon.button {
    min-width: 352px !important;
    margin-top: 10px;
  }

  .searchFieldText {
    margin-left: 14px;
  }
}

@media only screen and (min-width: 992px) {
  /* .Component-label-18 {
		font-size: 15px !important;
	}  */
  .applyReset {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .applyReset {
    width: 100%;
  }
}

/* .makeStyles-content-10 {
    padding-left: 11px !important;
    padding-right: 16px !important;
	width: 100%;
} */

/* Dashboard Mobile-Responsive */
@media only screen and (max-width: 600px) {
  .can-toggle label .can-toggle__switch {
    min-width: 200px;
  }

  .ticket-status-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .totalReviewBox canvas {
    height: auto !important;
    width: 100% !important;
  }

  .description .position-absolute {
    position: static !important;
  }

  .pr-4 {
    padding-right: 0px !important;
  }

  .chartjs-render-monitor {
    width: 300px !important;
    height: 300px !important;
  }

  .fp-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
    font-size: 13px !important;
  }

  /* .MuiTableCell-root {
		padding: 4px !important;
	} */
  .provider-label {
    font-size: 14px !important;
    padding: 7px 11px !important;
  }

  .circular-button {
    padding: 6px 6px !important;
  }

  .headerLogo {
    height: 23px;
    display: none !important;
  }

  .makeStyles-large-14 {
    width: 37px !important;
    height: 37px !important;
  }

  .gap-15 {
    gap: 10px !important;
  }

  .gap-10 {
    gap: 10px !important;
  }

  .navBar-user {
    font-size: 16px !important;
  }

  .makeStyles-drawerClose-8 {
    display: none;
  }

  .makeStyles-content-10 {
    padding: 0 24px !important;
  }

  .svgMobile svg {
    display: none;
  }

  .makeStyles-menuButton-4 {
    margin-right: 12px !important;
  }
}

@media only screen and (min-width: 600px) {
  .ticket-status-wrapper {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .headerLogo {
    height: 40px;
  }

  .makeStyles-drawerClose-8 {
    display: block;
  }
}

.custom-modal-style-tag {
  width: 100% !important;
}

.custom-dropdown-style {
  max-width: 168px !important;
}

.Select-arrow-zone {
  background: white;
}

.Select-clear-zone {
  background: white;
}

.custom-modal-ticket-details-style {
  width: 1100px !important;
}

.ticketResolutionPagination {
  background-color: #fafafb !important;
  padding: 8px 0 !important;
  align-items: center !important;
}

.ticketResolutionPagination li {
  display: inline !important;
  padding: 5px 9px !important;
  margin: 2px 5px !important;
  background-color: #f6f6f8;
  color: #1b1c1d;
}

.ticketResolutionPagination--active {
  background-color: #f33d88 !important;
  border-radius: 4px !important;
  color: white !important;
}

.ticketResolutionPagination__link {
  color: black !important;
}

.ant-switch {
  display: flex !important;
  align-items: center !important;
  min-width: 45px !important;
  height: 25px !important;
  /* border-radius: 4px !important; */
}

.ant-switch-handle {
  top: 2.5px !important;
  /* left: 4px !important;
	right: 4px !important; */
  width: 20px !important;
  height: 20px !important;
  /* border-radius: 0px !important; */
}

.ant-switch-inner {
  display: flex !important;
  flex-direction: row !important;
  font-size: 14.5px !important;
  font-weight: 500 !important;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 21px - 2px) !important;
}

.ant-switch-handle::before {
  border-radius: 3px !important;
  left: 3px !important;
}

.popup .MuiPaper-elevation8 {
  box-shadow: 2px -5px 15px -3px #0000000d, 0px 10px 15px -3px #0000001a;
}

.horizontal_Scroll {
  overflow-x: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-left: -15px;
}

.insights-NavRow .nav-tabs .nav-link {
  min-width: 140px;
  color: #646565 !important;
  text-align: center;
  border: 2px solid transparent !important;
}

.insights-NavRow .nav-tabs .nav-link.active {
  border-color: transparent;
  color: #f13a88 !important;
  /* border-bottom: 2px solid 'F13A88 !important; */
  border-bottom-color: #f13a88 !important;
}

.EmojiPickerReact > div:last-child {
  display: none;
}

.AssignmentRule-Rating .dv-star-rating-full-star {
  margin-top: 0px;
  padding: 2px 0px;
}

.social-card-container {
  /* display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); */
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-gap: 20px;
}

/* changes start */
@media only screen and (min-width: 768px) {
  .social-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .social-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 360px) and (max-width: 768px) {
  .social-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .social-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .social-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .social-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1025px) {
  .social-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 319px) {
  .social-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Google map tooltip */
.gm-ui-hover-effect {
  position: absolute !important;
  right: 0;
}

.gm-ui-hover-effect > span {
  height: "20px" !important;
  width: "20px" !important;
}

.gm-style-iw-d {
  padding-right: 40px;
}

.gm-style-iw-ch {
  padding-top: 13px;
}

.gm-style-iw {
  padding-bottom: 6px !important;
}

/* TagInputComp */
.inputKeyword::placeholder {
  color: #cbc8c8;
  font-size: 13px;
  font-weight: 400;
}

.highlight-row {
  background-color: #eee3ff;
  font-weight: 600;
}

.selectLocation {
  border: 1px solid #00b5ad;
  border-radius: 10px;
  padding: 20px 15px 25px 15px;
  background-color: white;
}

.menu-table .ant-table-cell:first-child {
  text-align: left;
  white-space: nowrap;
  max-width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.send-btn {
  height: 36px;
  width: 36px;
  border: none !important;
  margin-left: 15px !important;
}

.textAreaUI {
  width: 100%;
  border-radius: 8px;
  border-color: #bfc2cb;
  font-weight: 400;
  font-size: 14px;
  color: #7a8193;
  padding-left: 12px;
  padding-top: 5px;
}

.input-Field {
  width: 100%;
  border: 1px solid #bfc2cb;
  height: 40px;
  padding-left: 12px;
  border-radius: 8px;
  color: #7a8193;
}

.input-Field::placeholder {
  color: #7a8193;
}

.amcharts-funnel-tick {
  /* stroke-dasharray: 5; */
}

.amcharts-chart-div svg + a {
  display: none !important;
}

.ui.button.edit-btn {
  border-radius: 80px;
  padding: 8px 33px;
}

.dining_delivery > div:not(:last-child) {
  border-right: 1px solid #bfc2cb;
}

.custom-progress-bar .progress-bar {
  background-color: #00b5ad !important;
}

.login-Card {
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 10px;
  padding: 90px 110px 70px 110px;
  background: #BFC2CB33;
  box-shadow: 0 4px 30px #93959833;
  backdrop-filter: blur(16px);
}
.signUpLink {
  color: #f13a88;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
.signUpLink:hover {
  text-decoration: none;
  color: #f13a88;
}

tr.MuiTableRow-root.hideLastBorder:last-child {
  border-bottom: none !important;
}

.sort-dropdown{
  border-radius: 20px !important;
  width:99px !important;
  height:32px !important;
}

.upload-file {
  border-radius: 16px !important;
  width:182px !important;
  height:32px !important;
}
.assign-box {
  border-radius: 16px !important;
  height:28px !important;
}
.assign-box-fp-chip{
  border-radius: 16px !important;
  height:28px !important;
  padding:1px 10px !important;
}
.high-value-assign-box-fp-chip{
  border-radius: 16px !important;
  height:25px !important;
  padding:1px 10px !important;
}
.assign-name-box{
  border-radius: 16px !important;
  height:28px !important;
}
.review-search .fp-input{
  height: 32px !important;
  width: 260px;
  border-radius:8px;
}
.ticket-search .fp-input{
  height: 32px !important;
  width: 320px;
  border-radius:8px;
}
.review-search .fp-input input{
  padding: 7px 12px 7px 40px;
  margin-right: 10px;
}

.top-tag-view{
  width:100%;
  padding:8px 12px;
  border: 1px solid #BFC2CB;
  border-radius:99px;
  display:flex;
  gap:15px;
  align-items: center;
}
.permissionBox {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  padding: 10px;
  border-radius: 8px;
}
.permissionLable {
  font-weight: 500;
  color: #1b1c1d;
  font-size: 13px;
}
.paddingClass {
  padding:6px 15px !important;
}

.messageBoxType-btn-small{
  padding: 3px 10px !important;
}

.filterFPLable.MuiChip-outlined.MuiChip-sizeSmall.MuiChip-deletable{
  padding: 4px 12px;
}
.filterFPLable.MuiChip-outlined.MuiChip-sizeSmall.MuiChip-deletable span:first-child{
  margin-bottom: 1px;
}
.order-bg-view{
  border: 1px solid #BFC2CB;
  background-color: #FAFAFB;
  padding:10px 12px;
  border-radius: 8px;
}
.contact-filter-view{
  cursor: pointer;
  height:26px;
  width:26px;
  background-color: #1B1C1D;
  border-radius:99px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply-Btn, .AI-Summary{
  height: 30px !important;
}

.dropDownICon{
  width: 11px;
  height: 11px;
}

.carousel-container {
  position: relative;
}

.carousel-arrow-left,
.carousel-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 100;
}

.carousel-arrow-left {
  left: 20px;
}

.carousel-arrow-right {
  right: 20px;
}

.custom-pre {
  background: transparent !important; 
  overflow-x: auto; 
  scrollbar-width: none;
}

.custom-pre::-webkit-scrollbar {
  display: none;
}

.custom-code {
  color: #858D9D;
  font-size: 13px;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFB;
  border-radius: 20px !important;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.chart-heading {
  font-size: 16px;
  margin-bottom: 10px;
}

.sr-ticket-status-wrapper {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  grid-gap: 2rem;
}

.ui.card.sr-ticket-status {
  border: 1px solid var(--fp-secondary-outline) !important;
  margin: 0 !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sr-ticket-status p {
  margin: 0 !important;
}


.sr-ticket-status .header {
  display: flex;
  background-color: var(--fp-violet-red-50, #FDF2F7);
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--fp-secondary-outline);
  padding: 1px 9px;
}

.sr-ticket-status .header .sr-header-left {
  font-size: 12px;
  color: var(--violet-red-600, #E12169);
}

.sr-ticket-status .header .sr-header-right {
  font-size: 15px;
  font-weight: 500;
  color: var(----fp-secondary-font-dark, #1B1C1D);
}

.sr-ticket-status .time-title {
  color: var(--fp-secondary-blue-gray, #7A8193);
  font-size: 14px;
}

.sr-ticket-status .time-caption {
  color: var(--fp-secondary-color, #00B5AD);
  font-size: 18px;
  font-weight: 500;
}

.sr-ticket-status .time-section-wrapper {
  margin: 0 !important;
}

.sr-ticket-status .time-section {
  padding: 0 !important;
  text-align: center;
}

.sr-ticket-status .header.sr-header {
  border-radius: 0px !important;
  box-shadow: none !important;
  min-height: 38px;
}

.sr-chartBox-shadows {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .075) !important;
}

.sr-pr-container {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.sr-pr-progress-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sr-pr-progress-bar {
  display: flex;
  width: 100%;
  height: 20px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.sr-pr-progress-bar-segment {
  position: relative;
}

/* Tooltip styles */
.sr-pr-tooltip {
  text-align: center;
}

.sr-pr-tooltip-label {
  font-weight: bold;
  color: #000;
}

.sr-pr-tooltip-value {
  color: #555;
}

.sr-pr-tooltip-hidden {
  opacity: 0;
}

.sr-pr-total {
  text-align: right;
  color: #333;
  padding-left: 5px;
  font-size: 16px;
  font-weight: bold;
}

.sr-pr-legend {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.sr-pr-legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sr-pr-legend-color {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.sr-pr-legend-label {
  font-size: 14px;
  color: #555;
  font-weight: 500;
  text-transform: capitalize;
}

.file-item {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  max-width: 200px; 
  border: 1px solid #bfc2cb;
  border-radius: 10px;
  padding: 0px 5px ;
  font-size: 12px;
}

.upload-ui-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0px 1px;
  margin-left: 5px;
}

.upload-section {
  display: flex;
  flex-direction: column;
}

.upload-label {
  font-size: 13px;
  color: #333;
  margin-top: -5px;
}

.upload-area {
  display: flex;
  align-items: center;
  gap: 16px;
}

.attachment-preview-container,
.file-preview-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
}

.attachment-preview {
  position: relative;
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
  border: 1px solid white;
  border-radius: 99px;
  background-color: #D0D3D9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.complaints-attachment-preview {
  position: relative;
  width: 70px;
  height: 70px;
  transition: transform 0.3s ease;
  border: 1px solid white;
  border-radius: 99px;
  background-color: #D0D3D9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attachment-preview:hover {
  transform: scale(1.1);
  z-index: 5 !important;
}
.complaints-attachment-preview:hover {
  transform: scale(1.1);
  z-index: 5 !important;
}

.attachment-thumbnail {
  width: 20px;
  height: 20px;
}

.clear-icon {
  position: absolute;
  top: -4px;
  right: -4px;
  background: white;
  color: black;
  border-radius: 50%;
  padding: 2px;
  transition: opacity 0.3s ease;
  cursor: pointer;
  opacity: 0;
}

.attachment-preview:hover .clear-icon,
.file-preview-circle:hover .clear-icon {
  opacity: 1;
}

.file-preview-circle {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border: 1px solid white;
  transition: transform 0.3s ease;
  border-radius: 99px;
}

.file-preview-circle:hover {
  transform: scale(1.1);
  z-index: 5 !important;
}

.upload-button {
  width: 30px;
  height: 25px;
  border: 1px solid #bfc2cb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  cursor: pointer;
}

.upload-button.upload-new-file {
  width: auto;
  padding: 10px;
  border-radius: 70px;
  display: flex;
  gap: 5px;
}

.upload-button:hover {
  background-color: #f1f1f1;
}

/* Media Viewer Container */
.media-viewer-container {
  overflow-y: auto;
  background-color: black;
  color: white;
  text-align: center;
  padding: 0px 20px;
  position: relative;
  min-height: 554px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* Main Media Display */
.media-viewer-main {
  position: relative;
  display: inline-block;
  width: 90%;
  height: 100%;
  min-height: 300px;
}

/* Arrows */
.arrow-icon {
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 10;
  color: white;
  font-size: 30px;
  transform: translateY(-50%);
}

.left-arrow {
  left: -40px;
}

.right-arrow {
  right: -40px;
}

.disabled-arrow {
  color: gray;
  pointer-events: none;
}

/* Media Display */
.media-viewer-media {
  width: 90%;
  height: calc(100vh - 12rem);
  object-fit: cover;
}

/* Preview Bar */
.preview-bar {
  display: flex;
  justify-content: center;
  gap: 10px;
  overflow-x: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: #00000066;
  padding: 5px;
}

/* Thumbnail Styles */
.thumbnail-container {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 2px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.active-thumbnail {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.2);
}

.thumbnail-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

.thumbnail-icon-container {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  border-radius: 5px;
}

.thumbnail-icon {
  color: white;
}

.unsupported-thumbnail {
  font-size: 10px;
  text-align: center;
  color: white;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.radio-group input[type="radio"] {
  margin: 0;
  color: #f13a88 !important;
}

.radio-group label {
  margin: 0;
  font-size: 14px; 
}

.sr-fp-attachment-icon {
  position: absolute;
  z-index: 1;
  left: 5px;
  bottom: 5px;
}

.attachment-container {
  position: relative;
  display: inline-block;
}

.attachment-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.sr-dropdown-menu {
  position: absolute;
  top: 30px;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 180px;
}

.sr-dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sr-dropdown-item img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.sr-dropdown-item span {
  font-size: 14px;
  color: #333;
}

.sr-dropdown-item:hover {
  background-color: #f5f5f5;
}

.sr-comment-avatar.MuiAvatar-root {
  width: 22px;
  height: 22px;
}


.Select-value-label {
  display: flex !important ;
      align-items: center;
}

.ai-ads-progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px auto;
}

.ai-ads-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

.ai-ads-circle {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  color: #7A8193;
  font-size: 16px;
  margin-bottom: 5px;
  z-index: 2;
  border: 1px solid #BFC2CB;
}

.ai-ads-label {
  font-size: 14px;
  color: #555;
}

.ai-ads-step.active .ai-ads-circle {
  background-color: #00B5AD;
  color: #ffffff;
}

.ai-ads-step.checked .ai-ads-circle {
  background-color: #92CACE;
  color: #ffffff;
  border: none;
}

.ai-ads-progress-bar .line {
  position: absolute;
  height: 2px;
  background-color: #BFE0E2;
  width: 100%;
  top: 33%;
  left: 100%;
  z-index: 1;
  transform: translateX(-50%);
}

.completed-line {
  background-color: #00B5AD;
}

.ai-ads-step.checked ~ .line {
  background-color: #00B5AD;
}

.fame-ai-table .MuiTableBody-root td.MuiTableCell-root.MuiTableCell-body:empty::before {
  content: "" !important;
}

.historyHeaderText,
.historyHeaderTextColor,
.historyBodyText {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.searchHistoryTable .fp-table.fp-table-center .MuiTableBody-root .MuiTableRow-root .historyBodyText {
  background-color: #FDF2F780;
  color: #F13A88 !important;
  font-weight: 600 !important;
}

.searchHistoryTable .fp-table.fp-table-center .MuiTableHead-root .MuiTableRow-root .historyHeaderTextColor {
  color: #F13A88 !important;
  font-weight: 600 !important;
}

.department-list label{
margin-bottom: 0;
}

.designation-list label{
margin-bottom: 0;
}


.custom-twitter-picker {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

[id^="rc-editable-input-"] {
  display: none !important;
}

.high-value-customer-badge {
  color: #1B1C1D;
  font-size: 12px;
  background-color: #DDEFF0;
  border: 1px solid #438E96;
  padding: 3px 12px;
  border-radius: 50px;
  margin-bottom: 0;
}

.chip-text {
  font-size: 12px;
}

#chart-container > span > svg > g:last-of-type {
  display: none !important;
  visibility: hidden !important;
}

.email-user-name {
  font-size: 16px;
  font-weight: 600;
  color: #1B1C1D;
}

.text-container .makeStyles-root-1 {
  height: 100% !important;
}

.text-container .makeStyles-content-10 {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.store-container {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.store-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  padding: 10px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  text-align: center;
  width: 50%;
  background-color: white;
}

.store-icon {
  width: 120px;
  margin-bottom: 5px;
}

.store-text {
  text-align: center;
}

.store-bold {
  font-weight: bold;
  font-size: 16px;
}
