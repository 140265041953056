.sidenav-wrapper {
    min-height: calc(100vh - 80px) !important;
    /* margin-top: 19px; */
    flex-grow: 1;
}

.sidenav-wrapper .ui.vertical.menu {
    display: flex !important;
    flex-direction: column !important;
    background: #ffffff !important;
    box-shadow: none;
    border: none;
    padding-top: 19px;
    gap: 5px
}

.sidenav-items {
    height: 73vh;
    overflow-y: auto;
    width: 100% !important;
    display: flex;
    flex-direction: column;
}

.sidenav-wrapper .side-bar-icon {
    font-size: 16px;
    width: 20px;
}

.side-bar-icon-performance {
    font-size: 16px;
    width: 20px;
    margin-left: -9px !important;
}

.collapsed-sidenav-wrapper.sidenav-wrapper .side-bar-icon {
    width: auto;
}

.sidenav-wrapper .side-bar-title {
    line-height: 13px;
    font-size: 13px;
}

.sidenav-wrapper .accordian-icon {
    margin-top: 5px !important;
}

.sidenav-wrapper .ui.menu.item {
    padding: 0;
}

.sidenav-wrapper .ui.menu .item,
.sidenav-wrapper .ui.accordion.menu .item .title {
    padding: 0px 15px !important;
}

.sidenav-wrapper .ui.accordion.menu .item .content {
    padding-top: 0;
}

.sidenav-wrapper .nested-list-item:first-child a.nav-list-item {
    padding-top: 8px !important;
}

.sidenav-wrapper .nav-list-item,
.sidenav-wrapper .nav-list-item a {
    padding: 0 !important;
    text-transform: uppercase !important;
    color: var(--fp-secondary-blue-gray);
    font-size: 12px;
}

.sidenav-wrapper .item.nav-list-item.accordion-list {
    color: var(--fp-secondary-blue-gray);
    font-size: 14px;
}

.sidenav-wrapper .accordion-list a {
    padding: 0 !important;
}

.sidenav-wrapper .ui.accordion.menu .nested-list-item .nav-list-item {
    text-decoration: none;
    font-size: 12px !important;
}

.sidenav-wrapper .active-side-nav {
    /* background-color: var(--fp-surface-color, #f6f6f8) !important; */
    /* border-radius: 12px 12px 0 0; */
    border-radius: 99px;
    background: #F13A88;
}

.sidenav-wrapper .item .active.title .list .content {
    color: white;
    padding: 0.5px 0px;
    border-radius: 50px;
}

.sidenav-wrapper .item .content.active {
    /* background-color: var(--fp-surface-color, #f6f6f8) !important; */
    border-radius: 0 0 12px 12px;
    padding-top: 0;
    
}

.sidenav-wrapper .item:hover a {
    text-decoration: unset;
}

.sidenav-wrapper .list-item:hover .nav-list-item:not(a.active-side-nav),
.sidenav-wrapper .nested-list-item:hover .nav-list-item {
    color: #ec588d !important;
}

.sidenav-wrapper .nav-list-item {
    display: block;
    padding: 7px 7px !important;
    position: relative;
    margin-bottom: 5px;
}

.nav-list-item-options {
    display: flex;
    align-items: center;
    padding: 7px 19px !important;
    position: relative;
    margin-bottom: 5px;
    color : #7A8193;
}
.nav-list-item-options:hover {
    color: #ec588d !important;
}
.sidenav-wrapper[data-sidebar-open='false'] .nav-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidenav-wrapper .list-item .active-side-nav,
.sidenav-wrapper .item .title:not(.active) .list.active-side-nav {
    font-weight: 500;
    border-radius: 80px;
    background-color: var(--fp-primary-color) !important;
    color: #ffffff;
}

.sidenav-wrapper .item .title:not(.active) .list.active-side-nav .nav-list-item {
    color: #ffffff;
}

.sidenav-wrapper .item .title:hover .list:not(.active-side-nav) .nav-list-item {
    color: #ec588d;
}

.sidenav-wrapper .item .nested-list-item .active-side-nav {
    font-weight: 500;
    color: var(--fp-primary-color) !important;
    background: #FDF2F7;
    border-radius: 99px;
}

.sidenav-wrapper .item .nested-list-item .active-side-nav-acc {
    font-weight: 500;
    color: var(--fp-primary-color) !important;
    background: #FDF2F7 !important;
    border-radius: 99px;
    display: flex;
    align-items: center;
}

.sidenav-wrapper .ui.vertical.menu>.item:first-child a.nav-list-item:hover {
    text-decoration: none;
}

.sidenav-wrapper .ui.vertical.menu .item i.icon.sort.descending {
    margin-top: -9px !important;
}

.sidenav-wrapper .ui.vertical.menu .item i.icon.sort.ascending {
    margin-top: 3px !important;
}

.sidenav-wrapper .ui.vertical.menu .item:before {
    background-color: transparent;
    height: unset;
    width: unset;
}


.list-setup {
    margin-top: auto;
    margin-bottom: 20px;
    margin-top: auto;
}

.list-setup .title:not(.active) .list,
.list-setup>.nav-list-item {
    background-color: var(--fp-surface-color);
    border-radius: 80px !important;
}

.last-2-second {
    margin-bottom: 60px;
}

.items-text {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 13px;
    margin-left: 8px;
}

.NavLeftIcon i.fas.fas.fa-ticket-alt.side-bar-icon {
    min-width: 10px;
}

.currentLocationText p:hover {
    color: #ec588d !important;
}

.currentLocationText p:hover span {
    color: #ec588d !important;
}

.SetupButton {
    color: #00B5AD !important;
    margin-bottom: 22px;
}

.SetupButton:hover {
    color: #00B5AD !important;
}


.nav-list-item.active-side-nav.SetupButton {
    background-color: #00B5AD !important;
}

.nav-list-item.SetupButton {
    background-color: #00B5AD20;
    border-radius: 99px;
    border: 1px solid #00B5AD !important;
    transition: all 0.3s ease-in-out;
}

.nav-list-item.SetupButton:hover {
    background-color: #00B5AD !important;
}

.nav-list-item.active-side-nav.SetupButton > div {
    color: white !important;
}

.nav-list-item.SetupButton > div{
    color: black !important;
}

.nav-list-item.SetupButton:hover > div{
    color: white !important;
}

.nav-list-item.SetupButton > div p {
    color: inherit !important;
}
