.fp-mobile {
    height: 38px;
    border: 1px solid #bfc2cb;
    transition: border-color 0.3s, box-shadow 0.3s;
    border-radius: 8px;
    padding: 10px 12px;
}

.fp-mobile input {
    border-radius: 8px;
    position: relative;
    background-color: #fff;
    border: 0px solid #bfc2cb !important;
    font-size: 12px;
    width: 100%;
    padding: 0px;
    padding-left: 5px;
    font-family: 'Outfit', sans-serif !important;
    box-shadow: none !important;
    border-color: #bfc2cb;
    line-height: 1;
    outline: 0;
    height: 100%;
}

.fp-mobile:focus-within {
    box-shadow: rgba(0, 142, 255, 0.25) 0px 0px 0px 0.2rem;
    border-color: rgb(0, 142, 255);
}

.fp-mobile.error {
    border-color: #f44336 !important;
}

.fp-mobile.error:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25) !important;
}

.PhoneInputCountryIconImg {
    opacity: 0;
}

.PhoneInputCountryIcon--border {
    all: unset;
    width: 18px;
    height: 18px;
    box-shadow: none !important;
}

.mobile-picker-country-name {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
}