.postModalContainer {
    display: flex;
    width: 100%;
    gap: 20px;
    /* grid-template-columns: auto 1fr; */
}

.postTitleDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F6F6F8;
    padding-bottom: 6px;
}

.postTitleDate p:first-child {
    color: #1A2544;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

.postTitleDate p:last-child {
    color: #00B5AD;
    font-size: 16px;
    font-weight: 500;
}

.postTypePlatform {
    display: flex;
    margin-top: 16px;
}

.postTypePlatform div {
    width: 190px;
}

.postTypePlatform div p:first-child {
    margin-bottom: 0;
    color: #646565;
}

.postTypePlatform div p:last-child {
    margin-bottom: 0;
    color: #1B1C1D;
}

.fp-location-table .MuiTableCell-root {
    padding: 8px;
}