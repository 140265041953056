:root {
  --fp-primary-color: #F13A88;
  --fp-primary-color-light: #FEEFF5;
  --fp--violet-red-600: #e12369;
  --fp-violet-red-50: #FDF2F7;
  --fp-secondary-color: #00B5AD;
  --fp-Primary-TILLA-KARI: #00CBCB;
  --fp-surface-color: #f6f6f8;
  --fp-Secondary-Surface-2: #FAFAFB;
  --fp-secondary-outline: #BFC2CB;
  --fp-secondary-blue-gray: #7A8193;
  --fp-secondary-font-blue: #1A2544;
  --fp-secondary-font-dark: #1B1C1D;
  --fp-secondary-font-gray: #646565;
  --fp-red: #ff4545;
  --fp-orange: #ffa534;
  --fp-amber: #ffe234;
  --fp-yellowGreen: #b7dd29;
  --fp-parrotGreen: #57e32c;
  --fp-Blue-Chill-50: #F2F9F9;

  --fp-School-bus-Yellow-500: #FFD800;
  --fp-Gray-500: #667085;

  /* z-index */
  --fp-zIndex-loader: 999999;
}


/** COLOR CLASS */

.font-white {
  color: #ffffff;
}

.font-primary {
  color: var(--fp-primary-color) !important;
}

.font-secondary {
  color: var(--fp-secondary-color) !important;
}

.font-dark {
  color: var(--fp-secondary-font-dark) !important;
}

.font-blue {
  color: var(--fp-secondary-font-blue) !important;
}

.font-gray {
  color: var(--fp-secondary-font-gray) !important;
  font-size: 16px;
}

.font-red {
  color: var(--fp-red) !important
}

.font-green {
  color: var(--fp-parrotGreen) !important
}

.font-gray-500 {
  color: var(--fp-Gray-500) !important;
}

.font-blue-gray {
  color: var(--fp-secondary-blue-gray) !important;
}

/* BORDER */
.fp-border {
  border: 1px solid #F6F6F8;
}

.b-1,
.border {
  border: 1px solid var(--fp-secondary-outline) !important;
}

.b-1-light,
.fp-border-light {
  border: 1px solid #EAECF0 !important;
}

.b-radius-8 {
  border-radius: 8px !important;
}

.b-radius-4 {
  border-radius: 4px !important;
}

.border-left-8 {
  border-radius: 8px 0 0 8px !important;
}

.border-right-8 {
  border-radius: 0 8px 8px 0 !important;
}

.bottom-radius-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.top-radius-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/* MARGIN */

.-mx-15 {
  margin: 0 -15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

/* 20 */
.-mx-20 {
  margin: 0 -20px !important;
}

.mx-20 {
  margin: 0 20px !important;
}

.my-20 {
  margin: 20px 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

/* 10 */
.-mt-10 {
  margin-top: -10px !important;
}

.-mb-10 {
  margin-bottom: -10px !important;
}

.-mr-10 {
  margin-right: -10px !important;
}

.-ml-10 {
  margin-left: -10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

/* PADDING */
.fp-p-20 {
  padding: 20px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pl-8 {
  padding-left: 3rem !important;
}

.pl-4Rem {
  padding-left: 4rem !important;
}

.pl-5Rem {
  padding-left: 5.5rem !important;
}

.pl-7Rem {
  padding-left: 7.5rem !important;
}

.pl-10 {
  padding-left: 10px !important;
}

/* FONT-SIZE */
.fp-size-12 {
  font-size: 12px !important;
}

.fp-size-13 {
  font-size: 13px;
}

.fp-size-14 {
  font-size: 14px;
}

.fp-size-16 {
  font-size: 16px;
}

.fp-size-18 {
  font-size: 18px;
}

.fp-size-20 {
  font-size: 20px;
}

.fp-size-24 {
  font-size: 24px;
}

/* ---- FLEX ----- */
/* GAP */
.fp-flex-grow-1 {
  flex-grow: 1;
}

.fp-flex-grow-2 {
  flex-grow: 2;
}


/* GAP */

.gap-4 {
  gap: 4px;
}
.gap-5 {
  gap: 5px;
}

.gap-50{
  gap: 50px;
}

.gap-30 {
  gap: 30px;
}

.gap-20 {
  gap: 20px;
}

.gap-15 {
  gap: 15px;
}

.gap-10 {
  gap: 10px;
}

.gap-6 {
  gap: 6px;
}

.gap-y-10 {
  row-gap: 10px;
}

.gap-x-10 {
  column-gap: 10px;
}

.gap-y-20 {
  row-gap: 20px;
}

.gap-x-20 {
  column-gap: 20px;
}


/* BORDER / RADIUS */

.fp-card-outline {
  border: 1px solid var(--fp-secondary-outline) !important;
  border-radius: 12px !important;
}

.fp-card-outline-secondary {
  border: 1px solid var(--fp-secondary-color) !important;
}


/* FONT-WEIGHT */
.weight-400 {
  font-weight: 400 !important;
}

.weight-500 {
  font-weight: 500 !important;
}

.weight-600 {
  font-weight: 600 !important;
}

.weight-700 {
  font-weight: 700 !important;
}


/* HEIGHT */
.min-vw-100 {
  min-height: 100vh !important;
}

.max-vw-100 {
  max-height: 100vh !important;
}

.height-48 {
  min-height: 48px !important;
}

.height-38 {
  min-height: 38px !important;
}

.min-height-300 {
  min-height: 300px !important;
}

/* POINTER-EVENT */
.pointer-events-none {
  pointer-events: none !important;
}


/* LINE_HEIGHT */
.line-height-1 {
  line-height: 1 !important;
}

.w-90 {
  width: 90px !important;
}

/* width */
.w-300 {
  width: 300px !important;
}
.w-360 {
  width: 360px !important
}

.min-w-100px {
  min-width: 100px !important;
}

.max-w-100 {
  max-width: 100% !important;
}

/* OTHER */
.fp-card-header {
  color: var(--fp-secondary-font-gray, #646565) !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  z-index: 10;
  position: relative;
}

.fp-card-header.font-dark {
  color: var(--fp-secondary-font-dark) !important;

}

.bordered-text {
  background-color: #F6F6F8;
  width: fit-content;
  padding: 5px;
  border-radius: 15px;
  color: #1B1C1D;
  padding: 10px;
  margin-bottom: 10px;
}

.response-text {
  padding: 10px;
  width: 100%;
  border: 2px solid #F6F6F8;
  color: #1B1C1D;
}

.currentLocation {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  color: #1B1C1D !important;
  align-items: center;
}

.MessageReviewBox {
  border-radius: 18px 18px 0px 18px !important;
  color: #1B1C1D !important;
  weight: 400;
}

.commonHeader:hover {
  background-color: #DDEFF0;
}

.emoasdfas {
  color: #438E96 !important;
}

.active-template {
  border: 1px solid #438E96;
}

.textBreak {}

.fp-vertical-small-divider {
  width: 1px;
  height: 15px;
  background-color: #BFC2CB !important;
}

.fp-select-as-small-btn {
  padding: 0 15px !important;
  border-radius: 50px !important;
  text-transform: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Outfit', sans-serif !important;
  line-height: 1 !important;
  font-weight: 500;
  height: 32px;
  min-width: 60px;
  color: white;
  border: unset !important;
  background-color: #28314F !important;
}

.fp-select-as-small-btn.fp-primary-bg {
  background-color: var(--fp-primary-color) !important;
}

.fp-select-as-small-btn>p {
  line-height: 1;
}


.fp-full-card {
  background: white;
  padding: 16px;
  border-radius: 12px;
}

.MuiTableBody-root td.MuiTableCell-root.MuiTableCell-body:empty::before {
  content: '-';
  font-size: initial;
}

.MuiTableHead-root .MuiTableCell-root.MuiTableCell-head:hover {
  background-color: #DDEFF0;
}

.fp-table.fp-table-border .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root,
.fp-table.fp-table-border .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  border: 1px solid #E8E8E8 !important;
}

.fp-table-border {
  border: 1px solid #E8E8E8 !important;
}

/* Text Area */
.fp-textArea {
  width: 100%;
  border: 1px solid var(--fp-secondary-outline);
  padding: 10px 12px;
  position: relative;
  font-size: 13px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  background-color: transparent;
  color: var(--fp-secondary-font-dark);
}

.clean-small-avatar.MuiAvatar-root {
  width: 15px;
  height: 15px;
  border-radius: 0;
}

.clean-small-avatar.MuiAvatar-colorDefault {
  background-color: transparent;
}

/* Custom Radio button */
input[type='radio'] {
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  outline: none;
  transition: box-shadow 0.3s ease;
  border: 1px solid var(--fp-secondary-outline);
}

input[type='radio']:checked {
  border-color: var(--fp-secondary-font-blue) !important;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  margin: 3px auto;
  border-radius: 50%;
}

input[type='radio']:checked::before {
  background: var(--fp-secondary-font-blue) !important;
}

.ui.radio.checkbox .box:before,
.ui.radio.checkbox label:before {
  border-color: var(--fp-secondary-outline);
}

.input[type='radio']:checked:before,
.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
  background-color: var(--fp-secondary-font-blue) !important;
}

/* Normal checkbox */
.ui.checkbox input:checked~.box:before,
.ui.checkbox input:checked~label:before,
.ui.checkbox input:checked:focus~label:before {
  border-color: var(--fp-secondary-font-blue) !important;
}

.ui.checkbox input:focus~label:before {
  border-color: var(--fp-secondary-font-blue) !important;
}

input[type="checkbox"] {
  accent-color: var(--fp-secondary-font-blue) !important;
}

input[type="checkbox"].primary  {
  accent-color: var(--fp-primary-color) !important;
}


.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
  font-size: 11px;
}

.ui.checkbox label,
.ui.checkbox+label {
  color: var(--fp-secondary-font-blue);
}


/* Primary color checkbox */
.ui.checkbox.primary input:checked~.box:before,
.ui.checkbox.primary input:checked~label:before,
.ui.checkbox.primary input:checked:focus~label:before {
  border-color: var(--fp-primary-color) !important;
}

.ui.checkbox.primary input:focus~label:before {
  border-color: var(--fp-primary-color) !important;
}

.ui.checkbox.primary input:checked~.box:after,
.ui.checkbox.primary input:checked~label:after {
  color: var(--fp-primary-color) !important;
  font-size: 11px;
}

.ui.checkbox.primary input[type="checkbox"] {
  accent-color: var(--fp-primary-color) !important;
}

.ui.checkbox.primary input:checked~.box:after,
.ui.checkbox.primary input:checked~label:after {
  color: var(--fp-primary-color) !important;
}

/*  */
.ui.sr-label-capital:has(>input) label {
  text-transform: capitalize;
}



.v-divider {
  border-left: 1px solid #dee2e6;
}

/* OVERRIDE FONT-FAMILY */
.header {
  font-family: 'Outfit', sans-serif !important;
}

.dashboard-tab button {
  font-family: 'Outfit', sans-serif !important;
}

/* FP-Button font-family */
.fp-table th {
  font-family: 'Outfit', sans-serif !important;
}

/* Contact, Campaign Tab */
.MuiTabs-fixed button {
  font-family: 'Outfit', sans-serif !important;
}

.fp-table td {
  font-family: 'Outfit', sans-serif !important;
}

.ui {
  font-family: 'Outfit', sans-serif !important;
}

.card-title {
  font-family: 'Outfit', sans-serif !important;
}

a {
  font-family: 'Outfit', sans-serif !important;
}

.ui.form input[type="text"] {
  font-family: 'Outfit', sans-serif !important;
}
input[type="text"] {
  font-size:12px !important;
}

form h5 {
  font-family: 'Outfit', sans-serif !important;
}

.MuiMenuItem-root,
.MuiTypography-body1 {
  font-family: 'Outfit', sans-serif !important;
}

h2,
h3,
label,
.MuiChip-label,
input[type="text"],
input:not([type='range']) {
  font-family: 'Outfit', sans-serif !important;
}

.roundedButton {
  background-color: #f13a88;
  border-radius: 20px;
  color: #ffff;
  border: none;
  padding: 0px 20px;
  font-size: 18px;
}

.roundedButton:hover,
.roundedButton:active {
  background-color: #f13a88;
}

.w-30 {
  width: 290px !important;
}

.fp-outline-btn {
  background: transparent;
  height: 32px;
  min-width: 60px;
  color: rgb(27, 28, 29);
  border: 1px solid rgb(191, 194, 203);
  font-weight: 500;
  padding: 0 15px;
  border-radius: 50px;
  text-transform: none;
  font-size: 12px;
}

.link {
  text-decoration: none;
  color: #1a2544 !important;
}

.link:hover {
  text-decoration: none;
}

.threeDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: justify;
}

.fp-disabled {
  pointer-events: none;
  opacity: 0.5
}

.actionButton {
  display: flex !important;
  justify-content: center;
  gap: 20px;
}

.rounded-popover .MuiPopover-paper {
  border-radius: 8px;
  width: 350px;
}

.star-tag {
  padding: 2px 10px;
  border-radius: 50px;
  background-color: #F6F6F8;
  color: #7A8193;
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 5px;
  border: 1px solid #BFC2CB;
}


.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row.fp-row-wrapper {
  margin: 0 -10px;
  height: 100%;
}


.MuiBadge-root .MuiBadge-colorError {
  background-color: #E0271A;
}

.MuiBadge-root .MuiBadge-colorPrimary {
  background-color: #17B26A;
}

.text-underline {
  text-decoration: underline;
}

/* Background-color */

.bg-surface{
  background-color: #F6F6F8 !important;
}

.two-line{
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bs-fp-popup-overlay.popup-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 1301 !important;
}

.popup-content.bs-fp-popup-content {
  z-index: 1301 !important;
}
.radio-label.MuiTypography-body1 {
  font-size:12px
}

.review-nps {
  padding: 7px 7px;
  font-weight: bold;
  color: rgba(0,0,0,0.7);
  background: rgba(64,224,208);
}

.size-15 {
  width: 15;
  height: 15;
}