/* Central Branch Nav Css */
ul.Central_nav.nav a.active.nav-link {
  background-color: #1a2544;
  border-radius: 10px;
  color: #ffffff;
}

.Central_nav.nav a.nav-link,
.Setup_Sub_Nav a.nav-link {
  color: #7a8193;
}

.Central_nav.nav a.nav-link:hover {
  color: #7a8193;
}

/* Setup Ticket Rules */
.Setup_Sub_Nav a.active.nav-link {
  background-color: #00b5ad;
  border-radius: 8px;
  color: #ffff;
}

.text_Radio {
  display: flex;
  justify-content: space-between;
  margin: 18px auto;
  color: #1a2544;
}

.text_RadioBox {
  border: 1px solid #bfc2cb;
  border-radius: 6px;
  padding: 0px 20px;
  margin: 15px auto;
}

/* Ticket Assignment Rule */
.d-flex.justify-content-between.align-items-center.ticketAssignment a.nav-link {
  font-size: 13px;
}

.textBold {
  /* font-size: 17px; */
  font-weight: 500;
  color: #1b1c1d;
}

.headText {
  color: #646565;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 4px;
}

.escalation-title {
  color: #1a2544;
  font-size: 16px;
  font-weight: 400;
}
.escalationText {
  font-size: 16px;
  color: #0f172acc;
}
.srReminderText {
  font-size: 16px;
  color: #0F172A;
  font-weight: '400';
}
.escalationColorText {
  color: #00b5ad;
  font-size: 16px;
  /* margin: 0px 20px 0px 20px; */
}
.srReminderColorText {
  color: #438E96;
  font-size: 16px;
  /* margin: 0px 20px 0px 20px; */
}
.ticketStatus {
  border: 0.1px solid #bfc2cb;
  padding: 7px 12px;
}
/* .leftStatus{
    border-radius: 8px 0px 0px 8px;
}
.rightStatus{
    border-radius: 0px 8px 8px 0px;
} */
.TicketStatusBox label.fp-badge-label {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.SrReminderStatusBox > .selected label.fp-badge-label {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #1B1C1D !important;
}

.SrReminderStatusBox label.fp-badge-label {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #7A8193 !important;
}

.srPriority {
  border: 1px solid #BFC2CB;
  padding: 7px 12px;
  border-right: 0px;
}

.srPriority:first-child {
 border-radius: 8px 0 0 8px;
}

.srPriority:last-child {
  border-radius: 0 8px 8px 0;
  border-right: 1px solid #BFC2CB;
}
.TicketStatusBox label.fp-badge-label {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.statusText {
  font-size: 16px;
  color: #0f172acc;
}
.selected {
  background-color: #d9f0f0;
}

div#mui-component-select-morethanfirst {
  padding: 8px 120px;
}

.morethanFirst-Input{
  border: none;
}

input.morethanFirst-email{
  border: none;
  outline: none;
}


input.morethanFirst-email:hover{
  cursor: pointer;
}

input.morethanFirst-email:focus{
  border-bottom: 1px solid #00B5AD;
}

.ui.fluid.multiple.selection.dropdown.escalateDropdown {
  border: transparent;
  box-shadow: unset !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}

.escalateDropdown a.label.ui {
  background: transparent !important;
}

.escalateDropdown a.ui.label:hover {
  text-decoration: none;
}