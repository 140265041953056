/*Toastify*/
.Toastify__toast-container--center {
	top: 40%;
	left: 40%;
	transform: translateY(50%);
}


.Toastify__toast {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    font-size: 14px;
    font-family: 'Outfit', sans-serif !important;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    color: white;
    min-height: 48px;
    font-weight: 500;
    padding: 6px 16px;
}

.Toastify__toast--error {
    background: #F44336
}

.Toastify__toast--info{
    background: #2196f3;
}

.copy-link-toaster{
    background: white !important;
    color: #667085;
    width:194px;
    border:1px solid #F6F6F8;
    border-radius: 8px !important;
    padding: 0px;
    height: 48px;
    padding-left: 15px;
    margin-left:0px;
}

.Toastify__toast-container--top-center{
    display: flex;
    justify-content: center;
}