.teamMember {
  border-radius: 50%;
  background-color: #F0F1F3;
  color: #f13a88;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: -7px;
  border-left: 3px solid white;
  padding: 10px;
  line-height: 1;
}

.statusDot{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.8;
  margin-right: 10px;
}