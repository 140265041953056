.Publisher_Sub_Nav a.active.nav-link {
  background-color: #1a2544;
  border-radius: 8px;
  color: #ffff;
}
.Publisher_Sub_Nav a.nav-link {
  color: #7a8193;
}
.imageDragBox {
  width: 100%;
  border: 1px solid #d0d5dd;
  padding: 20px;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 0px;
  text-align: center;
}

.eventDateSelector input.fp-date-picker.flatpickr-input {
  width: 100%;
}
.eventDateSelector .react-datepicker-wrapper {
  width: 100%;
}

.postDateSelector .react-datepicker-wrapper {
  width: 100%;
}

.selectedImg {
  border: 1px solid #d7dae0;
  border-radius: 8px;
  padding: 10px 14px;
  display: flex;
  gap: 13px;
  align-items: center;
  width: 100%;
}
.selectedImg img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}
.postDetails img {
  width: 100%;
  /* height: 30%; */
  border-radius: 10px;
  margin-top: 20px;
}

.alertMsg {
  margin: auto;
  width: 315px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #d0d3d9;
}

.todayDate{
  color: #00B5AD;
  font-size: 13px;
}

.simpleBtn {
  color: #F13A88;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  gap: 6px;
  padding-top: 10px;
}

.postBox {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
  margin: 10px 10px 6px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.imageCount {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(40%, -45%);
  background-color: #ffffff;
  color: #1B1C1D;
  border-radius: 50%;
  font-size: 12px;
  align-items: center;
  display: flex;
  width: 28px;
  height: 28px;
}

.errorMsg{
  font-size: 13px;
  color: red;
}