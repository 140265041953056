@media only screen and (max-width: 1600px) {
  .topicsNav {
    width: 76%;
  }
}

@media screen and (min-width: 1600px) {
  .topicsNav {
    width: 48%;
  }
}

/* Topic */

.topicsNav {
  width: fit-content;
}

.topic-review-type-tab.nav.nav-tabs {
  box-shadow: none;
  border: none;
  border-radius: none !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.topic-review-type-tab.nav.nav-tabs .nav-item:first-child .nav-link {
  border: 1px solid #d0d5dd;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.topic-review-type-tab.nav.nav-tabs .nav-item:last-child .nav-link {
  border: 1px solid #d0d5dd;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.topic-review-type-tab.nav.nav-tabs .nav-item .nav-link {
  border-radius: unset;
  border-bottom: 1px solid #d0d5dd;
  border-top: 1px solid #d0d5dd;
  background-color: #ffffff;
  color: var(--fp-secondary-font-dark, #1b1c1d);
}

.topic-review-type-tab.nav-tabs .nav-item .nav-link.active {
  color: var(--fp-secondary-font-dark, #1b1c1d);
  border-color: "#D0D5DD";
  background-color: #f9fafb;
}

.topic-mask {
  background: url(../../images/dummay-topic.png);
  height: 100%;
  position: relative;
  filter: blur(9px);
}

.topic-mask-text {
  text-align: center;
  padding-top: 40px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

/* Staff performance review modal */
.sentiment-filter {
  width: 150px !important;
  background-color: var(--fp-secondary-font-dark);
  border-radius: 99px !important;
  height: 32px !important;
}

.sentiment-filter input.MuiSelect-nativeInput {
  opacity: 1;
}

.sentiment-filter input::placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
}

.sentiment-filter .MuiSelect-root:focus {
  box-shadow: none !important;
}

.sentiment-filter .MuiSelect-root {
  background-color: transparent !important;
  border-radius: 99px !important;
  color: white !important;
}
