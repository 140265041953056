/* ================ The Timeline ================ */

#activity-log {
    width: 99%;
    margin: auto;
}

#sr-activity-log-content {
    /* max-height: 300px; */
}

#activity-tag-content {
    overflow: auto;
}

.sr-timeline {
    position: relative;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    font-family: 'Outfit', sans-serif !important;
    text-transform: capitalize;
}

.sr-timeline:before {
    position: absolute;
    left: 20px;
    top: 13px;
    content: ' ';
    display: block;
    /* width: 6px; */
    height: calc(100% - 88px);
    margin-left: -1px;
    border-left: 1px dashed #7A8193;
    /* background: rgb(80, 80, 80);
    background: -moz-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(30, 87, 153, 1)), color-stop(100%, rgba(125, 185, 232, 1)));
    background: -webkit-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
    background: -o-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
    background: -ms-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
    background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%); */
    z-index: 5;
}

.sr-timeline li {
    padding: 10px 0;
}


.direction-l .flag:before {
    position: absolute;
    content: ('');
    display: block;
    width: 24px;
    height: 24px;
    margin-top: -10px;
    background: #fff;
    border-radius: 99px;
    z-index: 10;
    margin-left: -15px;
    left: -18.5px;
    top: 11px;
    box-shadow: 0px 4px 6px 2px #0000001A;
}

.direction-r .flag:before {
    position: absolute;
    content: ' ';
    display: block;
    width: 24px;
    height: 24px;
    margin-top: -10px;
    /* background: #FAFAFB; */
    border-radius: 99px;
    z-index: 10;
    margin-left: -15px;
    left: -18.5px;
    top: 11px;
    box-shadow: 0px 4px 6px 2px #0000001A;
}

.direction-r .flag.remove:before {
    content: url('../../../images/svg/deleted.svg');
}
.direction-r .flag.add:before {
    content: url('../../../images/svg/added.svg');
}
.direction-r .flag.edit:before {
    content: url('../../../images/svg/edited.svg');
}
.direction-r .flag.edit.sentiment:before {
    content: url('../../../images/svg/smileyFace.svg');
}
.timeline-item.commented .flag:before {
    background-image: url('../../../images/svg/Commented.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
}

.timeline-item.assigned .flag:before {
    background-image: url('../../../images/svg/UserCeck.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
}

.timeline-item.created .flag:before {
    background-image: url('../../../images/svg/edited.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}
.timeline-item.status .flag:before {
    background-image: url('../../../images/svg/StatusChanged.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.timeline-item.priority .flag:before {
    background-image: url('../../../images/svg/PriorityChanged.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.timeline-item.RequestToComplaint .flag:before {
    background-image: url('../../../images/svg/Complaint.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.timeline-item.ComplaintToRequest.flag:before {
    background-image: url('../../../images/svg/Request.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.timeline-item.NameChanged.flag:before {
    background-image: url('../../../images/svg/NameChanged.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.timeline-item.MobileNumberAdded.flag:before {
    background-image: url('../../../images/svg/MobileNumberAdded.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.timeline-item.EmailAdded.flag:before {
    background-image: url('../../../images/svg/EmailAdded.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.timeline-item.RoomNoRemove.flag:before {
    background-image: url('../../../images/svg/RoomNo.svg');
    height: 24px;
    width: 24px;
    background-color: #FAFAFB;
    box-shadow: none;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.action-box{
    margin: 0px 26px 0 55px;
    background-color: #FAFAFB;
    border: 1px solid #BFC2CB;
    box-shadow: 0px 5px 6px -3px #0000001A;
    width : 100%;
    border-radius: 8px;
    padding: 10px 20px;
}

#sr-activity-log-content {
    margin-top: 15px;
    /* overflow-x: hidden; */
    height: auto;
  }
  
  #sr-activity-log-content::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }


.time {
    display: inline-block;
    padding: 4px 6px;
    background: rgb(248, 248, 248);
}


/* .direction-r .flag:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(248, 248, 248);
    border-width: 8px;
    pointer-events: none;
} */

/* .time-wrapper {
    display: inline;
    line-height: 1em;
    font-size: 0.66666em;
    color: rgb(250, 80, 80);
    vertical-align: middle;
} */
/* 
.direction-r .time-wrapper {
    float: right;
} */

/* .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
} */

/* .direction-r {
    position: relative;
    width: 300px;
    left: 0px;
} */

/* .flag-wrapper {
.direction-p {
    position: relative;
    width: 100%;
    left: 53px;
}

.flag-wrapper {
    position: relative;
    display: inline-block;
    text-align: center;
} */

/* .flag {
    position: relative;
    display: inline;
    background: rgb(248, 248, 248);
    padding: 6px 10px;
    border-radius: 5px;
    font-weight: 600;
    text-align: left;
} */
/* 
.direction-r .flag {
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
} */


/* .desc {
    margin: 1em 0.75em 0 0;
    font-size: 0.77777em;
    font-style: italic;
    line-height: 1.5em;
} */

/* .direction-r .desc {
    margin: 1em 0 0 0.75em;
} */


/* ================ Timeline Media Queries ================ */

@media screen and (max-width: 660px) {
    /* .timeline {
        width: 100%;
        padding: 4em 0 1em 0;
    } */
    /* .timeline li {
        padding: 2em 0;
    } */
    /* .direction-l, */
    /* .direction-r {
        float: none;
        width: 100%;
        text-align: center;
    } */
    /* .flag-wrapper {
        text-align: center;
    } */
    /* .flag {
        background: rgb(255, 255, 255);
        z-index: 15;
    } */
    /* .direction-l .flag:before,
    .direction-r .flag:before {
        position: absolute;
        top: -30px;
        left: 50%;
        margin-left: -9px;
    } */
    /* .direction-l .flag:after,
    .direction-r .flag:after {
        content: "";
        position: absolute;
        left: 50%;
        top: -8px;
        height: 0;
        width: 0;
        margin-left: -8px;
        border: solid transparent;
        border-bottom-color: rgb(255, 255, 255);
        border-width: 8px;
        pointer-events: none;
    } */
    /* .time-wrapper {
        display: block;
        position: relative;
        margin: 4px 0 0 0;
        z-index: 14;
    } */
    /* .direction-l .time-wrapper {
        float: none;
    } */
    /* .direction-r .time-wrapper {
        float: none;
    } */
    /* .desc {
        position: relative;
        margin: 1em 0 0 0;
        padding: 1em;
        background: rgb(245, 245, 245);
        -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
        -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
        z-index: 15;
    } */
    /* .direction-l .desc,
    .direction-r .desc {
        position: relative;
        margin: 1em 1em 0 1em;
        padding: 1em;
        z-index: 15;
    } */
}

  