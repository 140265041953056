.performaceGraphNavTab .nav-tabs .nav-link {
  color: #f13a88 !important;
}
.performaceGraphNavTab .nav-tabs .nav-link.active {
  background-color: #fdf2f7;
  border-color: transparent;
  border-bottom: 2px solid #f13a88 !important;
}

.performanceView .nav-tabs .nav-link {
  color: #7a8193 !important;
}

.performanceView .nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
  border-bottom: transparent !important;
}

.performaceGraphNavTab .nav-tabs .nav-link:hover,
.performanceView .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.performance-box-grid-container {
  overflow-x: auto;
  /* padding-bottom: 10px; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
}

.performance-complaint-grid {
  /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); 
    /* width: 100%; */
  display: flex;
  gap: 10px;
}

.performance-complaint-info-card {
  padding: 10px 13px;
  border-radius: 8px;
  background-color: white;
  position: relative;
  cursor: pointer;
  /* min-width: 159px;  */
  border-bottom: 2px solid #f13a88;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.performance-complaint-card-text {
  font-weight: 500;
  color: #646565;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* @media screen and (max-width: 2600px) and (max-height: 100vh) {
    .performance-complaint-grid {
      display: flex; 
      flex-direction: row; 
      gap: 10px;
      padding: 10px 0;
    }
  
    .performance-complaint-info-card {
      flex: 0 0 224px; 
    }
  
    .performance-complaint-grid {
      -ms-overflow-style: none;
    }  
  } */

.performanceFilter .ui.dropdown:not(.button) > .default.text,
.ui.default.dropdown:not(.button) > .text,
.ui.dropdown.select-range,
.Select-placeholder,
.ui.search.dropdown > .text {
  color: #646565;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
}

.performanceFilter .ui.dropdown.select-range {
  width: 250px !important;
  padding: 8px !important;
}

/* .Report-Filter-Popup .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
      width: 360px;
  } */

.performanceFilter span.Select-arrow {
  border: solid #7a8193 !important;
  border-width: 0px 1px 1px 0px !important;
  transform: rotate(45deg) !important;
  padding: 3px;
}

.performanceFilter .ui.dropdown > .dropdown.icon:before {
  border: solid #7a8193 !important;
  border-width: 0px 1px 1px 0px !important;
  transform: rotate(45deg) !important;
  padding: 3px;
}

.custom-size-cart {
  height: 500px;
  width: 500px;
}
.custom-pie-table-box {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
@media screen and (max-width: 1620px) {
  .custom-pie-table-box {
    display: flex;
    flex-direction: column;
  }
}
