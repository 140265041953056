.fp-loader {
  z-index: var(--fp-zIndex-loader) !important;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}

.loader:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
    border-color: #8c6daf;
  }

  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }

  20% {
    border-color: #ef5d74;
  }

  40% {
    border-color: #f9a74b;
  }

  60% {
    border-color: #60beeb;
  }

  80% {
    border-color: #fbef5a;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    border-color: #8c6daf;
  }

  20% {
    transform: scale(1.2);
    border-color: #ef5d74;
  }

  40% {
    transform: scale(1);
    border-color: #f9a74b;
  }

  60% {
    transform: scale(1.2);
    border-color: #60beeb;
  }

  80% {
    border-color: #fbef5a;
  }
}